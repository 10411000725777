import React from 'react'
import { Button, Checkbox, Container } from '@mui/material';
import Input from '../../components/input/Input';
import { Box } from '@mui/system';

import * as unitService from '../../services/unitService';
import * as decisionService from '../../services/decisionService';
import { getProjectId } from '../../utils/utils';
import { toast } from 'react-hot-toast';

export default class DecisionTypeConfig extends React.Component {
    state = {
        title: '',
        units: [],
        budget: 0,
        deadline: null,
        description: '',
        selectedUnits: [],
    }

    async componentDidMount() {
        const units = await unitService.getUnitsOfProject(getProjectId());
        this.setState({ units })
      
    }

    handleUnitSelection = (unitId) => {
        const { selectedUnits } = this.state;
        selectedUnits.includes(unitId) ? 
            this.setState({ selectedUnits: selectedUnits.filter(u => u != unitId)}) :
            this.setState({ selectedUnits: [...selectedUnits, unitId ]}) 
    }

    createDecisions = async () => {
        try {
            const decision = {
                title: this.state.title,
                unitIds: this.state.selectedUnits,
                budget: this.state.budget,
                deadline: this.state.deadline,
                description: this.state.description
            }

            await decisionService.createDecisions(getProjectId(), decision);
            toast.success('Beslissing aangemaakt!')
            this.props.history.push('/decisions');
        } catch (error) {
            toast.error(`Er ging iets mis` )
        }
        
    }
    

    render() {
        const { units } = this.state;
      
        return(
            <Container>
              <h1>Nieuwe beslissing</h1>
              <Input label='Titel' onChange={(e) => this.setState({ title: e.target.value })}/>
                <Box sx={{ marginY: 3 }}>
                    <h3>Units</h3>
                    <div>
                        {units.map(u => <div>
                            {u.name}
                            <Checkbox onChange={() => this.handleUnitSelection(u._id)}/>
                        </div>)}
                    </div>
                </Box>
                <Box sx={{ marginY: 3}}>
                    <Input label='Budget' type='number' step="0.01" onChange={(e) => this.setState({ budget: e.target.value})}/>
                </Box>
                <Box sx={{ marginY: 3}}>
                    <Input label='Deadline' type='date' step="0.01" onChange={(e) => this.setState({ deadline: e.target.value})}/>
                </Box>
                <Input label='Standaard beschrijving' onChange={(e) => this.setState({ description: e.target.value })}/>
                <Box sx={{ marginY: 3}}>
                    <Button onClick={() => this.createDecisions()}>Maak bessling</Button>
                </Box>      
            </Container>
        )
    }
}
