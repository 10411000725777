import { Button, Card, Grid } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react'

import * as unitService from '../../services/unitService'
import AddIcon from '@mui/icons-material/Add';


export default class UnitsOverview extends React.Component {
    state = {
       units: [],
       clients: []
    }

    async componentDidMount() {
        const units = await unitService.getUnitsOfProject(this.props.projectId)
        this.setState({units })
    }

    

    render() {
        const { units } = this.state;
        const { projectId } = this.props;

        return(
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <h2>Units ({units.length})</h2>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
                <Button startIcon={<AddIcon />} onClick={() => { this.props.history.push(`/manage/projects/${projectId}/units/config?mode=create`)}}>Nieuwe unit</Button>

                <Grid container rowSpacing={1} columnSpacing={1}>
                    {units.map(u => <Grid item md={4} xs={12}>
                        <Card onClick={() => this.props.history.push(`/manage/projects/${projectId}/units/config?mode=edit&id=${u._id}`)}>
                            <p>{u.name}</p>
                            <p>{u.ownerId && `${u.ownerId.firstName} ${u.ownerId.lastName}` || 'Geen eigenaar'}</p>
                        </Card>
                    </Grid> )}
                </Grid>
           </div>
        )
    }
}
