import { Box, Button, Chip, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react'
import { toast } from 'react-hot-toast';
import Input from '../../components/input/Input';

import * as partnerService from '../../services/partnerService';
import * as userService from '../../services/authService';
import { USER_ROLES } from '../../utils/utils';

export default class PartnerConfig extends React.Component {
    state = {
        _id: '',
        name:'',
        vat: '',
        location: '',
        chosenUsers: [],
        allUsers: []
    }

    async componentDidMount() {
        const allUsers = await userService.getUsers();
        this.setState({ allUsers: allUsers.filter(u => u.role == USER_ROLES.SUPPLIER || u.role == USER_ROLES.TECHNICAL) });

        const id = new URLSearchParams(this.props.location.search).get("id");
        const mode =  new URLSearchParams(this.props.location.search).get("mode");
        if (mode == 'edit') {
            const partner = await partnerService.getPartner(id)
            const chosenUsers = allUsers.filter(u => partner.userIds.includes(u._id));
            this.setState({ _id: partner._id, name: partner.name, vat: partner.vat, chosenUsers, editing: true })
        }
    }

    savePartner = async () => {
        const { _id ,name, vat, chosenUsers, editing } = this.state;
        try {
            editing ?
                await partnerService.updatePartner(_id, { name, vat, userIds: chosenUsers.map(u => u._id) }) :
                await partnerService.createPartner({ name, vat, userIds: chosenUsers.map(u => u._id) })
            toast.success('Partner opgeslagen');
            this.props.history.push('/manage/partners')
        } catch (error) {
            toast.error(`Kon partner niet opslaan.`);
        }
    }    

    render() {
       const {  name, vat, allUsers, chosenUsers } = this.state;

       const ITEM_HEIGHT = 48; 
       const ITEM_PADDING_TOP = 8;
        const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
        };

        return(
            <Container>
                <h1>Partner configureren</h1>
                <Input name='Name' label='Naam' required value={name}  onChange={(e) => this.setState({ name: e.target.value })}/>
                <Input name='Vat' label='BTW nummer' required value={vat}  onChange={(e) => this.setState({ vat: e.target.value })}/>
                <h2>Werknemers</h2>
                <Select fullWidth
                MenuProps={MenuProps}
                multiple
                value={chosenUsers}
                onChange={(e) => this.setState({ chosenUsers: [...e.target.value]})}
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((user) => (<Chip key={user._id} label={`${user.firstName} ${user.lastName}`} />))}
                    </Box>
                )}
                >
                {allUsers.map((user) => (<MenuItem key={user} value={user}>{`${user.firstName} ${user.lastName}`}</MenuItem>))}
            </Select>
            <Button sx={{ marginTop: '24px'}} onClick={() => { this.savePartner() }}>Wijzigingen opslaan</Button>

            </Container>
            
        )
    }
}
