import React from 'react'
import { Button, Card, Container, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';

import * as decisionService from '../../services/decisionService';
import '../../styles/admin-decisions.scss';
import { getRole, USER_ROLES } from '../../utils/utils';
import PartnerRightsDialog from '../../components/partner-rights-dialog/PartnerRigthsDialog';
import Icon from '../../components/icon/Icon';
import dayjs from 'dayjs';
import DecisionStatus from '../../components/decision-status/DecisionStatus';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';

export default class AdminDecisions extends React.Component {
    state = {
       decisions: [],
       decisionType: { _id: '', title: '' },
       rightsDialog: false,
       deleteDialog: false,
       decisionType: {
            title: '',
       }
    }   

    async componentDidMount() {
        const { decisionTypeId } = this.props.match.params;

        const decisions = await decisionService.getDecisionsOfType(decisionTypeId);
        const decisionType = await decisionService.getDecisionType(decisionTypeId);

        this.setState({ decisions, decisionType })
    }

    navigateDecisionEdit = (e, decisionId) => {
        const { decisionType } = this.state;
        e.stopPropagation();
        this.props.history.push(`/decisions/type/${decisionType._id}/decision-config?mode=edit&id=${decisionId}`)
    }
    
    deleteDecision = async (e, decisionId) => {
        e.stopPropagation();
        await decisionService.deleteDecision(decisionId)
        window.location.reload();
    }

    markForDelete = (e, deleteDecisionId) => {
        e.stopPropagation();
        this.setState({deleteDecisionId});
    }

    cancelDelete = (e) => {
        e.stopPropagation();
        this.setState({ deleteDecisionId: null })
    }

    deleteDecisionType = async () => {
        const { _id } = this.state.decisionType;
        await decisionService.deleteDecisionType(_id);
        this.setState({ deleteDialog: false })
        this.props.history.push(`/decisions`)
    }

    render() {
        const { decisions, decisionType, rightsDialog, deleteDialog } = this.state;
        const { isDesktop } = this.props;
        const role = getRole();

        return(
            <Container>
                <h1>{decisionType.title}</h1>
                {role == USER_ROLES.SUPER_ADMIN && <div className='decision-add-btn-wrapper'>
                    <Button startIcon={<Icon i='add' />} onClick={() => this.props.history.push(`/decisions/type/${decisionType._id}/decision-config`)}>Nieuwe unit</Button>
                    <Button className='rights-btn' startIcon={<Icon i='gavel' />} onClick={() => this.setState({ rightsDialog: true })}>Partner rechten beheren</Button>
                    <Button style={{ marginLeft: '16px', background: 'red'}} startIcon={<Icon i='delete' />} onClick={() => this.setState({ deleteDialog: true })}>Alle beslissingen verwijderen</Button>
                </div>}
                {isDesktop && <Card>
                    <div>
                        <Grid container>
                            <Grid className='decision-header' item xs='1'>Unit</Grid>
                            <Grid className='decision-header' item xs='3'>Klant</Grid>
                            <Grid className='decision-header' item xs='2'>Deadline</Grid>
                            <Grid className='decision-header' item xs='2'>Budget (€)</Grid>
                            <Grid className='decision-header' item xs='2'>Status</Grid>
                            <Grid className='decision-header' item xs='2'>Acties</Grid>

                        </Grid>
                            {decisions.map(d => {
                                const { name: unit } = d.unitId;
                                let unitOwnerName = 'Geen eigenaar';
                                if (d.unitId && d.unitId.ownerId) unitOwnerName = `${d.unitId.ownerId.firstName} ${d.unitId.ownerId.lastName}`

                                return <Grid  container className='decision-grid-item' onClick={() => this.props.history.push(`/decisions/${d._id}`)}>
                                    <Grid item xs='1'>{unit}</Grid>
                                    <Grid item xs='3'>{unitOwnerName}</Grid>
                                    <Grid item xs='2'>{dayjs(d.deadline).format('DD-MM-YYYY')}</Grid>
                                    <Grid item xs='2'>€ {d.budget}</Grid>
                                    <Grid item xs='2'><DecisionStatus status={d.status} /></Grid>
                                    {<Grid item xs='2'>
                                        {getRole() == USER_ROLES.SUPER_ADMIN && 
                                            <div className='decision-action-container'>
                                                <Icon i='edit' color='grey' onClick={(e) => this.navigateDecisionEdit(e, d._id)}/>
                                                <Icon i='delete' color='red' onClick={(e) => this.markForDelete(e, d._id)}/>
                                                <ConfirmDialog open={this.state.deleteDecisionId == d._id} title='beslissing verwijderen?'
                                                    confirmText='Verwijderen'
                                                    type='delete'
                                                    description='<b>Let op!</b> Deze actie is onomkeerbaar </br> Deze actie verwijderd ook het gesprek van deze beslissing.'
                                                    onClick={(e) => this.deleteDecision(e, d._id)} 
                                                    onClose={(e) => this.cancelDelete(e) }
                                                />
                                            </div>
                                        }
                                    </Grid>}


                                </ Grid>
                                }
                            )}
                    </div>

                </Card>}
                {!isDesktop && <div>
                {decisions.map((d, index) => {
                          const { name: unit } = d.unitId;
                          let unitOwnerName = 'Geen eigenaar';
                          if (d.unitId && d.unitId.ownerId) unitOwnerName = `${d.unitId.ownerId.firstName} ${d.unitId.ownerId.lastName}`

                            return <Grid container onClick={() => this.props.history.push(`/decisions/${d._id}`)}>
                                <Card className='mobile-decision-container'>
                                    <Grid item xs={12}  className='mobile-decision-property'>
                                        <div className='mobile-d-property-title'>Unit</div>
                                        <div>{unit}</div>
                                    </Grid>
                                    <Divider />
                                    <Grid item xs={12}  className='mobile-decision-property'>
                                        <div className='mobile-d-property-title'>Klant</div>
                                        <div>{unitOwnerName}</div>
                                    </Grid>
                                    <Divider />
                                    <Grid item xs={12}  className='mobile-decision-property'>
                                        <div className='mobile-d-property-title'>Deadline</div>
                                        <div>{dayjs(d.deadline).format('DD-MM-YYYY')}</div>
                                    </Grid>
                                    <Divider />
                                    <Grid item xs={12}  className='mobile-decision-property'>
                                        <div className='mobile-d-property-title'>Budget</div>
                                        <div>€ {d.budget}</div>
                                    </Grid>
                                    <Divider />
                                    <Grid item xs={12}  className='mobile-decision-property'>
                                        <div className='mobile-d-property-title'>Status</div>
                                        <div><DecisionStatus status={d.status}/></div>
                                    </Grid>
                                </Card>
                            </Grid>
                    })
                }
            </div>}
                {rightsDialog && <PartnerRightsDialog decisions={decisions} decisionType={decisionType} onClose={() => this.setState({ rightsDialog: false })}/>}
                {deleteDialog && 
                    <ConfirmDialog open={deleteDialog} title='Beslissing type verwijderen?'
                    confirmText='Verwijderen'
                    type='delete'
                    description='<b>Let op!</b> Deze actie is onomkeerbaar </br> Deze actie verwijderd ook de onderliggende beslissingen.'
                    onClick={() => this.deleteDecisionType()} 
                    onClose={() => this.setState({ deleteDialog: false }) }
                    />
                }
           </ Container>
        )
    }
}

export const WithScreenAdminDecisions = (props) => {

    const theme = useTheme();
    const isDesktop =  useMediaQuery(theme.breakpoints.up('md'));
    return  <AdminDecisions isDesktop={isDesktop} {...props} />
}