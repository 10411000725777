import { httpClient, s3Client } from "./index.js";

export const getUnitsOfProject = async (projectId) => {
    const response = await httpClient.get(`unit/project/${projectId}/`);
    return response.data;
}

export const createUnit = async (unit) => {
    const response = await httpClient.post(`unit/`, unit);
}

export const getUnits = async () => {
    const response = await httpClient.get(`unit/`);
    return response.data;
}

export const getUnitById = async (unitId) => {
    const response = await httpClient.get(`unit/${unitId}`);
    return response.data;
}

export const updateUnit = async (unit) => {
    const response = await httpClient.put('unit',  unit);
}

export const getAccesibleUnits = async (projectId) => {
    const response = await httpClient.get(`unit/project/${projectId}/accessible/chat`);
    return response.data;
}