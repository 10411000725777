import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel } from '@mui/material'
import toast from 'react-hot-toast';
import React from 'react'

import * as unitService from '../../services/unitService';
import * as fileService from '../../services/fileService';

import FileUpload from '../file-upload/FileUpload';
import { getProjectId, mapFileProps } from '../../utils/utils';


export default class FileUploadDialog extends React.Component {
    constructor(props) {
        super(props);
    this.state = {
        units: [],
        selectedUnits: [],
        files: [],
    }

    this.uploader = React.createRef();
}


    async componentDidMount() {
        const units = await unitService.getUnits();
        this.setState({ units });
    }

    handleUnitIdChange = (unitId) => {
        const { selectedUnits } = this.state;

        selectedUnits.includes(unitId) ?
            this.setState({ selectedUnits: selectedUnits.filter(id => id != unitId )}) :
            this.setState({ selectedUnits: [...selectedUnits, unitId ]});
    }
    
    saveFiles = async () => {
        const {files , selectedUnits} = this.state;
        try {
            const mappedFiles =  mapFileProps(files);
            const uploadUrls = await fileService.createGeneralFiles(getProjectId(),mappedFiles, selectedUnits);
            await this.uploader.current.upload(uploadUrls);
    
            toast.success('Documenten toegevoegd');
            this.props.onClose();

        } catch (error) {
            toast.error('Er ging iets mis');
            console.log(error);
        }
    }

    render() {
        const { units, selectedUnits, files } = this.state;

        return(
          <Dialog open={true} maxWidth='md'scroll='paper' onClose={() => this.props.onClose()}>
            <p style={{ width: '600px'}}></p>
            <DialogTitle>Algemene documenten toevoegen</DialogTitle>
            <Divider />
            <DialogContent>
                <p>units: </p>
                    {units.map(u => <div>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox checked={selectedUnits.includes(u._id)} onChange={() => this.handleUnitIdChange(u._id)}/>}
                            label={u.name}
                            labelPlacement="end"
                        />
                    </div>)}
                <p>Documenten</p>
                    <FileUpload ref={this.uploader} onChange={(files) => this.setState({ files })} />
            </DialogContent>
            <Button onClick={() => this.saveFiles()}>documenten opslaan</Button>
          </Dialog>
        )
    }
}
