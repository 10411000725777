import React from 'react'
import { Button, Container, Grid } from '@mui/material';
import { toast } from 'react-hot-toast';
import HtmlEditor from '../components/html-editor/HtmlEditor';
import Input from '../components/input/Input';
import { generateMongoDbId, getProjectId, getRole, USER_ROLES } from '../utils/utils'
import * as articleService from '../services/newsArticleService';
import NewsPreview from '../components/news-preview/NewsPreview';
import Icon from '../components/icon/Icon';

export default class News extends React.Component {
    state = {
        articles: []
    }

    async componentDidMount() {
        const articles = await articleService.getNewsArticles(getProjectId());
        this.setState({ articles })
    }
    

    render() {
       const { articles } = this.state;
       const isAdmin = getRole() == USER_ROLES.SUPER_ADMIN;
        return(
            <Container>
                {isAdmin && <Button startIcon={<Icon i='add' />} onClick={() => { this.props.history.push(`/news/config?mode=create`)}}>Maak nieuwsartikel aan</Button>}
                <Grid container columnSpacing={4} rowSpacing={2}> 
                   {articles.map(a => <Grid item md={6} xs={12}>
                     <NewsPreview {...a} edit={isAdmin} history={this.props.history}/>
                     </Grid>)}
                </Grid>
            </Container>
        )
    }
}
