import { Button, Card } from '@mui/material';
import React from 'react'

import * as dayjs from 'dayjs'

import { getFileUrl } from '../../utils/utils';
import './news-preview.scss';
import Icon from '../icon/Icon';

export default class NewsPreview extends React.Component {

    render() {
        const { files, content, createdAt, edit, history, _id } = this.props;
        const firstFile = files && files.length > 0 && files[0];
        const isImage = firstFile.type.includes('image');

        return(
           <Card className='news-preview-container'>
            <div className='news-p-media-container'>
                {firstFile && isImage && <img className='news-p-img' src={getFileUrl(firstFile)} />}
                {firstFile && !isImage && <video controls className='news-p-img' src={getFileUrl(firstFile)} />}

            </div>
            <div className='news-p-content-container' >
                <div>
                    <p className='news-p-date'>{dayjs(createdAt).format('DD MMMM')}</p>
                </div>
                <div className='news-content' dangerouslySetInnerHTML={{ __html: content}} />
                {edit && <Button startIcon={<Icon i='edit' />} onClick={() => history.push(`/news/config?mode=edit&id=${_id}`) }>Bewerken</Button>}
            </div>
           </Card>
        )
    }
}
