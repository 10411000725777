import React from 'react'
import { Avatar, Button, Card, Container, Divider, Grid, MenuItem, Select } from '@mui/material';

import Input from '../../components/input/Input';

import * as userService from '../../services/authService.js'
import toast from 'react-hot-toast';
import { USER_ROLES } from '../../utils/utils';

import '../../styles/config-users.scss';

export default class UserOverview extends React.Component {
    state = {
        users: [],
        allUsers: [],
        userDetails: {
            email: '',
            firstName: '',
            lastName: '',
            address: '',
            phoneNumber:'',
            role: '',
            verified: false,
            isActive: false
        },
        inviting: false,
    }

    async componentDidMount() {
        const users = await userService.getUsers();
        this.setState({users, allUsers: users})
        
        const userDetails = await userService.getMe();
        this.setState({userDetails})
    }
    
    async loadUserDetails(userId){
        this.setState({ inviting: false });
        const emptyDetails =  {
            email: '',
            firstName: '',
            lastName: '',
            address: '',
            phoneNumber:'',
            role: '',
            verified: false
        };
        this.setState({userDetails: emptyDetails})
        const userDetails = await userService.getUseryId(userId);
        this.setState({userDetails})
    }

    stringAvatar(name) {
        return {
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }

    startInviteProcess = () => {
        const userDetails = {
            email: '',
            firstName: '',
            lastName: '',
            address: '',
            phoneNumber:'',
            role: USER_ROLES.CLIENT,
            verified: false
        };
        this.setState({ inviting: true, userDetails  })
    }

    inviteContact = async () => {
        const {  verified, ...userData} = this.state.userDetails;
        try {
            await userService.inviteContact(userData);
            toast.success(`Contact succesvol uitgenodigd!`)
            window.location.reload();
        } catch (error) {
            toast.error('Er ging iets mis.')
        }
    }

    impersonateUser = async (userId) => {
        try {
            const { user, token } = await userService.impersonateUser(userId);
            localStorage.setItem('impersonating-token', token);
            localStorage.setItem('impersonating-user', `${user.firstName} ${user.lastName}`)
            localStorage.setItem('role', user.role)
            this.props.history.push('/documents')
            window.location.reload();
        } catch (error) {
            toast.error(`Er ging iets mis`)
        }
    }

    updateUser = async () => {
        const { firstName, lastName, role, address, phoneNumber, email, _id } = this.state.userDetails;
        try {
            await userService.updateUser(_id, { firstName, lastName, address, phoneNumber, role, email });
            toast.success('Gebruiker gewijzigd!');
            window.location.reload();
        } catch (error) {
            toast.error('Er ging iets mis...');
        }
    }

    resendInviteEmail = async () => {
        const {  email} = this.state.userDetails;

        try {
            await userService.resendInviteEmail({email});
            toast.success(`Uitnodiging opnieuw opgestuurd`)
        } catch (error) {
            toast.error('Er ging iets mis.')
        }
    }

    filterUsers = (search) => {
        if (!search) this.setState({users: this.state.allUsers})
        if (search) {
            const upperCaseSearch = search.replace(/\s/g, '').toUpperCase();
            const matchingUsers = this.state.allUsers.filter(u => 
                `${u.firstName}${u.lastName}`.replace(/\s/g, '').toUpperCase().includes(upperCaseSearch)|
                `${u.lastName}${u.firstName}`.replace(/\s/g, '').toUpperCase().includes(upperCaseSearch))
            this.setState({users: matchingUsers})
        }
    }

    activateUser = async () => {
        const { _id } = this.state.userDetails;
       try {
            await userService.setUserActive(_id);
            toast.success(`Gebruiker actief gezet`)
            window.location.reload();
        } catch (error) {
            toast.error('Er ging iets mis.')
        }
    }

    deActivateUser = async () => {
        const { _id } = this.state.userDetails;
       try {
            await userService.setUserInActive(_id);
            toast.success(`Gebruiker inactief gezet`)
            window.location.reload();
        } catch (error) {
            toast.error('Er ging iets mis.')
        }
    }

    render() {
        const { users , userDetails, inviting } = this.state;
        return(
            <Container>
                <h1>Contacten</h1>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Card>
                            <p>{users.length} contacten</p>
                            <br/>
                            <Input onChange={(e)=> this.filterUsers(e.target.value)}/>
                            {users.map(u => <p style={u.isActive ?{ cursor: 'pointer'}: { cursor: 'pointer', 'text-decoration': 'line-through'} } className={userDetails.email == u.email && 'user-selected'}
                                onClick={() => this.loadUserDetails(u._id)}>{u.firstName} {u.lastName}</p>)}
                            <Button onClick={() => this.startInviteProcess()}>Contact uitnodigen</Button>
                        </Card>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <Card>
                            <div className='c-user-container'>
                                {userDetails.firstName} {userDetails.lastName}
                                {!inviting && <Button onClick={() => this.impersonateUser(userDetails._id)}>Impersonatie</Button>}
                            </div>
                            <Divider sx={{ marginBottom: '12px'}}/>
                            <Input name='Voornaam' value={userDetails.firstName} type='text' label='Voornaam' required  onChange={(e) => this.setState({ userDetails: {...userDetails, firstName: e.target.value} })}/>
                            <Input name='Achternaam' value={userDetails.lastName} type='text' label='Achternaam' required  onChange={(e) => this.setState({ userDetails: {...userDetails, lastName: e.target.value} })}/>
                            <Input name='Email' type='email' value={userDetails.email} label='Email' required  onChange={(e) => this.setState({ userDetails: {...userDetails, email: e.target.value} })}/>
                            <Input name='Adres' value={userDetails.address} type='text' label='Adres' onChange={(e) => this.setState({ userDetails: {...userDetails, address: e.target.value} })}/>
                            <Input name='Telefoonnummer' value={userDetails.phoneNumber} type='text' label='Telefoonnummer' required  onChange={(e) => this.setState({ userDetails: {...userDetails, phoneNumber: e.target.value} })}/>
                            <p>ToegangsRechten</p>
                            <Select value={userDetails.role} onChange={(e) => this.setState({ userDetails: {...this.state.userDetails, role: e.target.value }})}>
                                {Object.values(USER_ROLES).map(r => <MenuItem value={r}>{r}</MenuItem>)}
                            </Select>
                            {userDetails.role == USER_ROLES.SUPER_ADMIN && <p>Heeft toegang tot alle projecten en inhoud op het Havis platform.</p>}
                            
                            {inviting && <div style={{ marginTop: '16px'}}>
                                <Button onClick={() => this.inviteContact()}>Nodig contact uit</Button>
                            </div>}
                            {(!userDetails.verified && !inviting )&& <div style={{ marginTop: '16px'}}>
                                <p>Dit account heeft zich nog niet geverifierd. Wilt u de email opnieuw sturen?</p>
                                <Button onClick={() => this.resendInviteEmail()}>resend email</Button>
                            </div>}
                            {userDetails.verified && !inviting && <div style={{ marginTop: '16px'}}>
                                <Button onClick={() => this.updateUser()}>Wijzigingen opslaan</Button>
                            </div>}
                            {(!userDetails.isActive && !inviting )&& <div style={{ marginTop: '16px'}}>
                                <Button style={ { background: 'green'}} onClick={() => this.activateUser()}>Activeer user</Button>
                            </div>}
                            {(userDetails.isActive && !inviting )&& <div style={{ marginTop: '16px'}}>
                                <Button style={ { background: 'red'}} onClick={() => this.deActivateUser()}>Deactiveer user</Button>
                            </div>}
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}