import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react'

export default class ConfirmDialog extends React.Component {
    render() {
        const { open, description, onClick, onClose, title, confirmText, cancelText, type } = this.props;

        return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
                {this.props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => onClose(e)}>{cancelText || 'Annuleer'}</Button>
                <Button style={type == 'delete' ? { background: 'red'}: {}} onClick={(e) => onClick(e)}>{confirmText || 'Doorgaan'}</Button>
            </DialogActions>
        </Dialog>
        )
    }
}
