import React from 'react';
import { Route } from 'react-router-dom';

export const CustomRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            const jwtToken = localStorage.getItem('jwt-token');
            // Add authentication logo here

          
            return <Component {...props} />
        }} />
    )
}

export default CustomRoute;