import { Button, Card, Container, Grid } from '@mui/material'
import React from 'react'

import '../../styles/config-overview.scss';

export default class ConfigOverview extends React.Component {

    render() {

        return(
            <Container>
                <Grid container columnSpacing={4}>
                    <Grid item md={4} xs={12}>
                        <Card className='config-card' onClick={() => this.props.history.push('/manage/projects')}>
                            Projecten
                        </Card>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Card className='config-card' onClick={() => this.props.history.push('/manage/users')}>
                            Contacten
                        </Card>
                    </Grid>
                    <Grid item md={4} xs={12} onClick={() => this.props.history.push('/manage/partners')}>
                        <Card className='config-card'>
                            Partners
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}
