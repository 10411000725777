import { Card, Chip, Grid } from '@mui/material'
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import React from 'react'
import Icon from '../icon/Icon';

import './decision-card.scss';
import { DECISION_STATUS } from '../../utils/utils';

export default class DecisionCard extends React.Component {
    render() {
        const { _id, title, history, status, decisionTypeId, unitId, showUnit } = this.props;
        let backgroundColor = '';

        if (status == DECISION_STATUS.APPROVED) backgroundColor = 'var(--color-success-light)'
        if (status == DECISION_STATUS.UNDECIDED) backgroundColor = '#808080';
        if (status == DECISION_STATUS.REFUSED) backgroundColor = '#ed8b8b';
        if (status == DECISION_STATUS.CHANGES_REQUESTED) backgroundColor = '#808080';

        return(
            <Grid item xs={12} md={4} className='decision-card-container' onClick={() => history.push(`/decisions/${_id}`)}>
                <Card className='decision-card-content-container'>
                    <div className='decision-card-status-container' style={{ backgroundColor }}>
                        {status == DECISION_STATUS.APPROVED && <Icon i='done_all' className='decision-card-status-icon approved-icon'/>}
                        {status == DECISION_STATUS.UNDECIDED && <Icon color='white' i='question_mark' className='decision-card-status-icon undecided-icon'/>}
                        {status == DECISION_STATUS.REFUSED && <Icon color='white' i='close' className='decision-card-status-icon undecided-icon'/>}
                        {status == DECISION_STATUS.CHANGES_REQUESTED && <Icon color='white' i='build' className='decision-card-status-icon undecided-icon'/>}

                    </div>
                    <div className='decision-card-title'>
                     {decisionTypeId && decisionTypeId.title} 
                     {showUnit && <Chip label={unitId && `${unitId.name}`}/>}
                    </div>
                </Card>
            </Grid>
        )
    }
}
