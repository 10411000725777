import React from 'react'
import { Card, Container, Grid } from '@mui/material';
import { DECISION_STATUS, getProjectId } from '../../utils/utils';
import DecisionCard from '../../components/decision-card/DecisionCard';

import * as decisionService from '../../services/decisionService';

import '../../styles/client-decisions.scss';

export default class ClientDecisions extends React.Component {
    state = {
       decisions: [],
       units: []
    }

    async componentDidMount() {
        const response = await decisionService.getDecisions(getProjectId())
        this.setState({ decisions: response.decisions, units: response.units });
    }   

    render() {
        const { decisions, units } = this.state;
        const undecided = decisions.filter(d => d.status == DECISION_STATUS.UNDECIDED);
        const decided = decisions.filter(d => d.status == DECISION_STATUS.APPROVED);
        const refused = decisions.filter(d => d.status == DECISION_STATUS.REFUSED);
        const changes =  decisions.filter(d => d.status == DECISION_STATUS.CHANGES_REQUESTED);

        return(
            <div>
                    {undecided.length > 0 && <div>
                        <h3 className='client-decision-title'>Nog te beslissen</h3>
                        <Grid container columnSpacing={2} rowSpacing={2}>
                            {undecided.map(d => <DecisionCard {...d} showUnit={units.length > 1} history={this.props.history} />)}
                        </Grid>
                    </div>}
                    {changes.length > 0 && <div>
                        <h3 className='client-decision-title'>Wijzigingen gevraagd</h3>
                        <Grid container columnSpacing={2} rowSpacing={2}>
                            {changes.map(d => <DecisionCard {...d} showUnit={units.length > 1} history={this.props.history} />)}
                        </Grid>
                    </div>}
                    {decided.length > 0 && <div>
                        <h3 className='client-decision-title'>Beslist!</h3>
                        <Grid container columnSpacing={2} rowSpacing={2}>
                            {decided.map(d => <DecisionCard {...d} showUnit={units.length > 1} history={this.props.history} />)}
                        </Grid>
                    </div>}
                    {refused.length > 0 && <div>
                        <h3 className='client-decision-title'>Geweigerd</h3>
                        <Grid container columnSpacing={2} rowSpacing={2}>
                            {refused.map(d => <DecisionCard {...d} showUnit={units.length > 1} history={this.props.history} />)}
                        </Grid>
                    </div>}
            </div>
        )
    }
}
