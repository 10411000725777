import React from 'react'
import { Button, Card, Container, Menu, MenuItem, TextField } from '@mui/material';

import * as unitService from '../services/unitService';
import * as conversationService from '../services/conversationService';
import * as decisionService from '../services/decisionService';
import Input from '../components/input/Input';
import { getProjectId, getRole, USER_ROLES } from '../utils/utils';



export default class NewConversation extends React.Component {
    state = {
        units: [],
        decisions: [],
        selectedUnit: null,
        selectedDecision: '',
        loading: true,
    }

    async componentDidMount() {
        const units = await unitService.getAccesibleUnits(getProjectId());
        if (units.length == 1) this.selectUnit(units[0]._id);
        this.setState({ units })
    }

    createConversation = async () => {
        const { selectedDecision, selectedUnit } = this.state;
        
        const conversation = await conversationService.createConversation(selectedDecision, selectedUnit);
        this.props.history.push(`/conversations/${conversation._id}`)
    }
    
    selectUnit = async (unitId) => {
        this.setState({ selectedUnit: unitId })

        const decisions = await decisionService.getDecisionTopicsOfUnit(unitId);
        if (getRole() != USER_ROLES.SUPPLIER) {
            this.setState({ decisions: [{ _id: 'GENERAL', type: 'Algemeen onderwerp' }, ...decisions] })
        } else {
            this.setState({ decisions: [...decisions] })
        }
    }

    render() {
        const { units, selectedUnit, decisions, selectedDecision } = this.state;

        const isAdmin = true;
        return(
            <Container>
              <h1>Nieuw gesprek</h1>
              <Card>
                {(units.length > 1 || getRole() != USER_ROLES.CLIENT) &&
                    <Input label='Unit' onChange={(e => this.selectUnit(e.target.value))} select children={units.map(u => <MenuItem value={u._id}>{u.name} - {u.ownerId ? `${u.ownerId.lastName} ${u.ownerId.firstName}` : 'Geen eigenaar'}</MenuItem>)}/>}
                {<Input label='Onderwerp' disabled={!(selectedUnit && decisions.length > 0)} onChange={(e) => this.setState({ selectedDecision: e.target.value })} 
                    select children={decisions.map(d => <MenuItem value={d._id}>{d.type}</MenuItem>)}/>}
                {(selectedUnit && decisions.length == 0) && <p>Chat topics aan het laden...</p>}
                <Button sx={{ marginTop: '16px'}} disabled={!(selectedUnit && selectedDecision)} onClick={() => this.createConversation()}>Gesprek starten</Button>
              </Card>
            </Container>
        )
    }
}
