import React from 'react'
import { toast } from 'react-hot-toast';
import { Button } from '@mui/material';

import '../styles/login.scss';

import * as authService from '../services/authService';
import Input from '../components/input/Input';

import logo from '../assets/black_logo.png';
import { Link } from 'react-router-dom';
import { USER_ROLES } from '../utils/utils';

export default class Login extends React.Component {
    state = {
     username: '',
     password: '',  
    }

    login = async (e) => {
        e.preventDefault();
        const { username, password } = this.state;
        try {
            const { user } = await authService.login(username, password);
            localStorage.removeItem('impersonating-token');
            toast.success(`Welkom ${user.firstName}!`);

            if (user.role == USER_ROLES.CLIENT) this.props.history.push('/dashboard');
            if (user.role == USER_ROLES.TECHNICAL) this.props.history.push('/documents');
            if (user.role == USER_ROLES.SUPPLIER) this.props.history.push('/decisions');
            if (user.role == USER_ROLES.SUPER_ADMIN) this.props.history.push('/decisions');
           
        } catch (error) {
            toast.error(`Inloggen mislukt.`);
        }
       
    }

    render() {
        return(
            <div className='login-container'>
                    <img src={logo} className='login-logo-img' />
                    <h1 className='login-title'>Login</h1>
                    <div className='login-submessage'>Aanmelden om verder te gaan</div>
                    <form method='post' onSubmit={this.login} className='login-form'>
                        <Input name='email' type='email' label='Email' required  onChange={(e) => this.setState({ username: e.target.value })}/>
                        <Input name='password' type='password' label='Wachtwoord' required  onChange={(e) => this.setState({ password: e.target.value })}/>
                        <Button className='login-btn' type='submit' fullWidth sx={{ marginTop: '12px'}}>Log in</Button>
                        <Link className='auth-link' to='/reset-password'>Wachtwoord vergeten?</Link>
                    </form>
            </div>
           
        )
    }
}
