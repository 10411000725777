import { Avatar, Card, Grid } from '@mui/material'
import React from 'react'
import dayjs from 'dayjs';

import './conversation-item.scss'

import Icon from '../icon/Icon';
import { getRole, USER_ROLES } from '../../utils/utils';

export default class ConversationItem extends React.Component {
    state = {
       
    }

    componentDidMount() {
    }   

    render() {
        const { decisionId, unitId, _id, history, partnerId, userId, read, messages } = this.props;
        const isPartnerConversation = partnerId != null;

        const userRead = read.find(r=> r.userId == userId);
        const hasUserRead = userRead != null;

        const lastMessage = messages.pop();
        const hasLastMessage = lastMessage != null

        const unitName = !isPartnerConversation ? unitId.name : '';
        const title = !isPartnerConversation ? decisionId.decisionTypeId.title : 'Partner gesprek';
        const unitOwner = !isPartnerConversation ? `${unitId?.ownerId?.firstName} ${unitId?.ownerId?.lastName}` : ''
        
        const partnerName = (isPartnerConversation && getRole() == USER_ROLES.SUPER_ADMIN) ? partnerId.name : 'Havis';

        return(
            <Card className='conversation-item' onClick={() => history.push(`/conversations/${_id}`)}>
                    <div className='conv-status-container'>
                            { (hasUserRead && hasLastMessage && lastMessage.senderId != userId && dayjs(lastMessage.timestamp).isAfter(userRead.timestamp)) &&
                                <div className='conv-unread'></div>
                            }
                    </div>
                    <div className='c-ind-wrapper'>
                        <Avatar>
                            {!isPartnerConversation && unitOwner.split(' ').map(w => w.charAt(0))}
                            {isPartnerConversation && partnerName.split(' ').map(w => w.charAt(0))}
                        </Avatar>
                    </div>
                    <div className='c-content-wrapper'>
                        {!isPartnerConversation && <div className='conversation-unit'>
                            <span >{unitName}</span>
                            <span> • {unitOwner} </span>
                        </div>}
                        { isPartnerConversation && <p className='conversation-unit'> {partnerName}</p>}
                        <p className='conversation-title'>{title}</p>
                    </div>
             
      
             
          </Card>
        )
    }
}
