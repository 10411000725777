import { Toaster } from 'react-hot-toast';


const StyledToaster = () => {

    const toastOptions = {
        className: '',
        style: {
            // border: '1px solid #713200',
            // padding: '16px',
            // color: '#713200',
          },
        success: {
          style: {
            // background: 'green',
          },
        },
        error: {
          style: {
            // background: 'red',
          },
        },
      }

    return <Toaster toastOptions={toastOptions} />
}

export default StyledToaster;