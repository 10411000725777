import { httpClient } from "./index.js";


export const createPartner = async (partner) => {
  const response = await httpClient.post(`partner`, partner);
  return response && response.data;
};

export const updatePartner = async (partnerId, partner) => {
    const response = await httpClient.put(`partner/${partnerId}`, partner);
    return response && response.data;
};

export const getPartner = async (partnerId) => {
    const response = await httpClient.get(`partner/${partnerId}`);
    return response && response.data;
};

export const getPartners = async () => {
  const response = await httpClient.get(`partner/`);
  return response && response.data;
}

export const upsertRights = async (rights) => {
  const response = await httpClient.put(`partner/set/rights`, rights);
  return response && response.data;
}