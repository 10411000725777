import React from 'react'
import { Button, Card, Container, Grid } from '@mui/material';

import * as decisionService from '../services/decisionService.js'
import { getProjectId, getRole, USER_ROLES } from '../utils/utils';
import FileUploadDialog from '../components/file-upload-dialog/FileUploadDialog';

import * as fileService from '../services/fileService';

import '../styles/documents.scss';
import Icon from '../components/icon/Icon.jsx';


export default class Documents extends React.Component {
    state = {
       decisionTypes: [],
       fileDialog: false
    }

    async componentDidMount() {
        const decisionTypes = await decisionService.getDecisionTypesForDocuments(getProjectId());

        this.setState({decisionTypes})
    }
    
    download = () => {
        fileService.download(getProjectId());
    }

    render() {
        const { decisionTypes, fileDialog } = this.state;
        return(
            <Container>
                {getRole() == USER_ROLES.SUPER_ADMIN && <div className='documents-controls-wrapper'>
                    <Button startIcon={<Icon i='add' />}  onClick={() => this.setState({ fileDialog: true })}>Voeg algemene documenten toe</Button>
                    <Button className='documents-download-btn' startIcon={<Icon i='download' />} onClick={() => this.download()}>Download</Button>
                </div>}
                <Grid container rowSpacing={1.5} columnSpacing={1.5}>
                    {decisionTypes.map(d => <Grid item md='3' xs='12'>
                        <Card className='decision-type-document' onClick={() => this.props.history.push(`/documents/${d._id}`)}>
                            <Icon i='folder' className='folder-icon'/><p className='decision-type-document-title'>{d.title}</p>
                        </Card>
                    </Grid> )}
                </Grid>
                {fileDialog && <FileUploadDialog  onClose={() => this.setState({ fileDialog: false })}/>}
            </Container>
        )
    }
}
