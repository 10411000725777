import { httpClient, s3Client } from "./index.js";
import { toast } from "react-hot-toast";
import { getFileUrl } from "../utils/utils.js";


export const getFiles = async () => {
    const response = await httpClient.get(`file/`);
    return response.data;
}

export const signFile = async (fileId) => {
    const response = await httpClient.post(`file/sign/${fileId}`);
    return response.data;
}

export const getFilesOfDecisionTypes = async (decisionTypeId, projectId) => {
    const response = await httpClient.get(`file/decisionType/${decisionTypeId}/${projectId}`);
    return response.data;
}

export const getFilesOfGeneral = async (projectId) => {
    const response = await httpClient.get(`file/decisionType/type/general/${projectId}`);
    return response.data;
}

export const uploadFiles = (uploadUrls, files) => {
    files.map(async file => {
        
        const options = { 
            headers: { 'Content-Type': file.type },
            onUploadProgress: (event) => {
            }  
        };

        const uploadUrl = uploadUrls.find(url => url.index == file.index);
        if (uploadUrl) {
            const upload =  s3Client.put(uploadUrl.uploadUrl, file, options);
        } 
    })
}

export const uploadHtmlEditorFile = async (file, objectId) => {
    const files = [{ name: file.name, type: file.type, index: 0, size: file.size }];
    try {
        // Get signed URL from backend
        const response = await httpClient.post(`file/`, { files, objectId });
        const { uploadUrl, previewUrl } = response.data[0];

        // Upload to S3
        const options = { headers: { 'Content-Type': file.type } };
        await s3Client.put(uploadUrl, file, options)
        return previewUrl;
    } catch (error) {
        console.log(error)
        toast.error('Could not upload file!')
    }
}

export const createGeneralFiles = async (projectId, files, units) => {
    const response = await httpClient.post(`file/documents/general`, { projectId, files, units });
    return response.data;
}

export const download = async (projectId) => {
    const response = await httpClient.get(`file/download/${projectId}`, { responseType: 'blob'});
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'file.zip'); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return response.data;
}

export const downloadFile = async (file) => {
    const response = await s3Client.get(`${getFileUrl(file)}`, { responseType: 'blob'});
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', file.name); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return response.data;
}

export const deleteFile = async (fileId) => {
    const response = await httpClient.delete(`file/${fileId}`);
    return response.data;
}