import React from 'react'


export default class Icon extends React.Component {

    render() {
        const { className, color, onClick } = this.props;
        return <span 
            class={`material-symbols-outlined ${className}`}
            style={{ color: color, cursor: this.props.onClick ? 'pointer': 'inherit' }}
            onClick={(e) => this.props.onClick(e)}
            >
            {this.props.i}
            </span>
    }
}
