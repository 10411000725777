import { Button } from '@mui/material';
import React from 'react'
import { USER_ROLES } from '../../utils/utils';

import './impersonating.scss';

export default class ImpersonatingContainer extends React.Component {

    componentDidMount() {
    }

    stopImpersonating = () => {
        localStorage.removeItem('impersonating-token');
        localStorage.setItem('role', USER_ROLES.SUPER_ADMIN);
        window.location.reload();
    }

    render() {
        const token = localStorage.getItem('impersonating-token');
        return false && <div className='impersonating-container'>
            <h1>Impersonating</h1>
            <Button onClick={() => this.stopImpersonating()}>Stop impersonating</Button>
        </div>
    }
}
