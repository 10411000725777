import React from 'react'
import { Editor } from '@tinymce/tinymce-react';

import * as fileService from '../../services/fileService';


export default class HtmlEditor extends React.Component {
    state = {
        value: ''
    }


    componentDidMount() {
    }

    deleteBranding = () => {
        document.getElementsByClassName('tox-statusbar__branding')[0].remove();
    }

    uploadImage = async (blobInfo) => {
        const previewUrl = await fileService.uploadHtmlEditorFile(blobInfo.blob(), this.props.objectId)
        return previewUrl;
    }

    handleChange = (html, _editor) => {
        this.setState({ value: html })
        this.props.onChange(html)
    }

    render() {
        return(
            <Editor
                apiKey={'txw6txk50i5226fx45v6tr82nbm804b7lx61yx521uad5ktr'} // TODO: softcode 
                onInit={(evt, editor) => editor.setContent(this.props.initialValue) && this.deleteBranding()}
                //initialValue= {this.props.initialValue}
                value={this.state.value}
                onEditorChange={(html, editor) => this.handleChange(html, editor)}
                init={{
                toolbar_mode:"wrap",
                height: 500,
                images_upload_handler: this.uploadImage,
                file_picker_types: 'file image media',
                file_browser_callback_types: 'file image media',
                menubar: false,
                plugins: [
                    'advlist', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'image', 'media', 'emoticons'
                ],
                toolbar: 'undo redo | blocks ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | image media link' +
                    'removeformat | emoticons | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        )
    }
}
