import { MenuItem, Select } from '@mui/material';
import React from 'react'
import { getProjectId } from '../../utils/utils';

import * as projectService from '../../services/projectService';

import './project-switcher.scss';

export default class ProjectSwitcher extends React.Component {
    state = {
       projects: [],
       selectedProject: null,
       selectingProject: false,
    }

    async componentDidMount() {
        const selectedProjectId = getProjectId();
        const projects = this.props.projects;
        
        if (!selectedProjectId) {
            this.setState({ selectedProject: projects[0] });
            localStorage.setItem('projectId', projects[0]._id);

        } else {
            const selectedProject = projects.find(p => p._id == selectedProjectId);
            this.setState({ selectedProject })
        }
        this.setState({ projects });
    }

    selectOtherProject(project) {
        this.setState({ selectedProject: project })
        localStorage.setItem('projectId', project._id);
        window.location.reload();
    }
    

    render() {
        const { projects, selectedProject, selectingProject } = this.state;
        return(
           <div className='project-switcher-container'>
            {!selectingProject && <p className='active-project'>{selectedProject && selectedProject.name}</p>}
            
            {projects.length > 1 && selectingProject && <Select fullWidth value={selectedProject} onChange={(e) => this.selectOtherProject(e.target.value)}>
                {projects.map(p => <MenuItem value={p}>{p.name}</MenuItem>)}
            </Select>}

            {projects.length > 1 && !selectingProject && 
                <p className='switcher-txt-btn' onClick={() => this.setState({ selectingProject: true })}>Ander project kiezen</p>}
           </div>
        )
    }
}
