import React from 'react'

import './data-field.scss';

export default class DataField extends React.Component {


    render() {
        const { label, value } = this.props;


        return(
            <div className='data-field'>
                <div className='data-field-label'>{label}</div>
                <div className='data-field-value'>€ {value}</div>
            </div>
        )
    }
}
