import React from "react";

import * as projectService from '../../services/projectService';

import { Container } from "@mui/system";
import { Button, Card, Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { getFileUrl } from "../../utils/utils";

import '../../styles/projects.scss';
import logo from '../../assets/havis_logo_small.png'


export default class Projects extends React.Component {
  state = {
    projects: []
  }

  async componentDidMount() {
    const projects = await projectService.getProjects();
    this.setState({ projects })
  }

  render() {
    const { projects } = this.state;
    return (
      <Container>
        <h1>Projecten</h1>
        <Button startIcon={<AddIcon />} onClick={() => this.props.history.push('/manage/projects/configure/config')}>Nieuw project</Button>
        <Grid container spacing={6}>
            {projects.map(p => <Grid item xs={12} md={4}>
                <Card sx={{ cursor: 'pointer', height: '100%', padding: '0px'}} onClick={() => this.props.history.push(`/manage/projects/${p._id}/`)}>
                  <div className="projects-banner-container">
                    {p.files && p.files.length > 0 && <img className="projects-image" src={getFileUrl(p.files[0])} />}
                    {p.files && !p.files.length && <img className="projects-image" src={logo}/>}
                  </div>
                  <div className="projects-info-container">{p.name}</div>
                </Card>
            </Grid>)}

        </Grid>
      </Container>
    );
  }
}
