import { httpClient } from "./index.js";

export const getMyConversations = async (projectId) => {
    const response = await httpClient.get(`conversation/project/${projectId}`);
    return response.data;
}

export const createConversation = async (decisionId, unitId) => {
    const response = await httpClient.post(`conversation`, { decisionId, unitId });
    return response.data;
}

export const getConversationDetails = async (conversationId) => {
    const response = await httpClient.get(`conversation/${conversationId}`);
    return response.data;
}

export const sendMessage = async (conversationId, message) => {
    const response = await httpClient.post(`conversation/chat/${conversationId}`, { message });
    return response.data;
}

export const sendAttachments = async (conversationId, files) => {
    const response = await httpClient.post(`conversation/chat/${conversationId}/attachments`, { files });
    return response.data;
}

export const createPartnerConversation = async (partnerId) => {
    const response = await httpClient.post(`conversation/createPartnerConversation`, { partnerId });
    return response.data;
}