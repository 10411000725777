import React from 'react'
import { Card, Chip, Container, Divider, Grid } from '@mui/material';

import * as fileService from '../services/fileService.js'
import FilePreview from '../components/file-preview/FilePreview.jsx';
import { getProjectId } from '../utils/utils.js';

export default class DecisionTypeDocuments extends React.Component {
    state = {
       unitFiles: [],
       decisionType: {
        title :''
       }
    }

    async componentDidMount() {
        const { decisionTypeId } = this.props.match.params;
        
        const projectId = getProjectId();
        const response = decisionTypeId !== 'General' ? await fileService.getFilesOfDecisionTypes(decisionTypeId, projectId) : await fileService.getFilesOfGeneral(projectId);

        this.setState({unitFiles: response.files, decisionType: response.decisionType})
    }
    
    render() {
        const { unitFiles, decisionType } = this.state;
        const { title } = decisionType;

        return(
            <Container>
                <h1>{title}</h1>
                <Grid container rowSpacing={1} columnSpacing={1}>
                {unitFiles.map((unitWithFiles, index) =>{ 
                return <Grid item xs={12} sx={{ marginBottom: '12px'}}>
                        <Chip size='medium' label={unitWithFiles.unitName}></Chip>
                        <Grid container columnSpacing={{ xs: 0, md: 2 }}>
                            {unitWithFiles.files.map(f => <Grid item xs={12} md={3}>
                                <FilePreview card file = {f} />
                            </Grid>)}
                        </Grid>
                        {index + 1  != unitFiles.length && <Divider />}
                        </Grid>})}

                </Grid>
            </Container>
        )
    }
}
