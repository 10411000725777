import { httpClient } from "./index.js";

export const getProjects = async () => {
    const response = await httpClient.get(`project/`);
    return response.data;
}

export const getProject = async (projectId) => {
    const response = await httpClient.get(`project/${projectId}`);
    return response.data;
}

export const updateProject = async (projectId, data) => {
    const response = await httpClient.put(`project/${projectId}`, data);
    return response.data;
}


export const createProject = async (data) => {
    const response = await httpClient.post(`project/`, data);
    return response.data;
}