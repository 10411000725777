import React from 'react'
import { Button, Card, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';

import * as decisionService from '../../services/decisionService';
import '../../styles/admin-decisions.scss';
import { DECISION_STATUS, getProjectId, getRole, USER_ROLES } from '../../utils/utils';
import AddIcon from '@mui/icons-material/Add';
import DecisionStatus from '../../components/decision-status/DecisionStatus';
import dayjs from 'dayjs';

class AdminDecisionTypes extends React.Component {
    state = {
       decisionTypes: []
    }

    async componentDidMount() {
      const decisionTypes = await decisionService.getDecisionGroup(getProjectId());
      this.setState({ decisionTypes });
    }
    
    render() {
        const { decisionTypes } = this.state;
        const { isDesktop } = this.props;
        const role = getRole();

        return(
            <>
            <div className='decision-add-btn-wrapper'>
                {role == USER_ROLES.SUPER_ADMIN && <Button startIcon={<AddIcon />} onClick={() => this.props.history.push('/decision-type-config')}>Nieuwe beslissing</Button>}
            </div>
           {isDesktop && <Card>
            <div>
                <Grid sx={{ marginBottom: '10px'}} container>
                    <Grid className='decision-header' item xs='3' md='4'>Beslissing</Grid>
                    <Grid className='decision-header' item xs='3' md='2'>Deadline</Grid>
                    <Grid className='decision-header' item xs= '3' md='1'>Units</Grid>
                    <Grid sx={{ display: 'flex', justifyContent: 'center' }} className='decision-header' item xs='3' md='5'>Status</Grid>
                </Grid>
                    {decisionTypes.map((d, index) => {
                        const nrDecisions = d.decisions.length;
                        const nrApprovedDecisions = d.decisions.filter(d => d.status == DECISION_STATUS.APPROVED).length;
                        const nrUndecidedDecisions = d.decisions.filter(d => d.status == DECISION_STATUS.UNDECIDED).length;
                        const nrChangesRequested = d.decisions.filter(d => d.status == DECISION_STATUS.CHANGES_REQUESTED).length;
                        const nrRefused = d.decisions.filter(d => d.status == DECISION_STATUS.REFUSED).length;
                        
                        const approvedPercentage = Math.round((nrApprovedDecisions / nrDecisions) * 100);
                        const undecidedPercentage = Math.round((nrUndecidedDecisions / nrDecisions) * 100);
                        const changesPercentage = Math.round((nrChangesRequested / nrDecisions) * 100);
                        const refusedPercentage = Math.round((nrRefused / nrDecisions) * 100);




                        return <>
                        <Grid  container className='decision-grid-item' onClick={() => this.props.history.push(`/decisions/type/${d._id}`)}>
                            <Grid item xs='3' md='4' className='decision-property decision-title'>{d.title}</Grid>
                            <Grid item xs='3' md='2' className='decision-property'>{d.deadline && dayjs(d.deadline).format('DD-MM-YYYY')}</Grid>
                            <Grid item xs='3' md='1' className='decision-property'>{nrDecisions}</Grid>
                            <Grid item xs='3' md='5'>
                                <div className='decisions-progress-counter'>
                                    <div className='decided-counter' style={{ width: `${approvedPercentage}%`}}>{ `${approvedPercentage}%`}</div>
                                    {nrUndecidedDecisions != 0 && 
                                    <div className='notdecided-counter' style={{ width: `${undecidedPercentage}%`}}>{`${undecidedPercentage}%`}</div> }
                                    {nrChangesRequested != 0 && 
                                    <div className='changes-counter' style={{ width: `${changesPercentage}%`}}>{`${changesPercentage}%`}</div>}
                                    {nrRefused != 0 &&
                                    <div className='refused-counter' style={{ width: `${refusedPercentage}%`}}>{`${refusedPercentage}%`}</div>}

                                </div>
                            </Grid>
                        </ Grid>
                        {index + 1 != decisionTypes.length && <Divider />}
                    </>
                    }
                    )}
            </div>
           </Card>}
           {!isDesktop && <div>
                {decisionTypes.map((d, index) => {
                        const nrDecisions = d.decisions.length;
                        const nrApprovedDecisions = d.decisions.filter(d => d.status == DECISION_STATUS.APPROVED).length;
                        const nrUndecidedDecisions = d.decisions.filter(d => d.status == DECISION_STATUS.UNDECIDED).length;
                        const nrChangesRequested = d.decisions.filter(d => d.status == DECISION_STATUS.CHANGES_REQUESTED).length;
                        const nrRefused = d.decisions.filter(d => d.status == DECISION_STATUS.REFUSED).length;
                        
                        return <Grid container onClick={() => this.props.history.push(`/decisions/type/${d._id}`)}>
                            <Card className='mobile-decision-container'>
                                <Grid item xs={12}  className='mobile-decision-property'>
                                    <div className='mobile-d-property-title'>Beslissing</div>
                                    <div>{d.title}</div>
                                </Grid>
                                <Divider />
                                <Grid item xs={12}  className='mobile-decision-property'>
                                    <div className='mobile-d-property-title'>Deadline</div>
                                    <div>{d.deadline && dayjs(d.deadline).format('DD-MM-YYYY')}</div>
                                </Grid>
                                <Divider />
                                <Grid item xs={12}  className='mobile-decision-property'>
                                    <div className='mobile-d-property-title'>Units</div>
                                    <div>{nrDecisions}</div>
                                </Grid>
                                <Divider />
                                <Grid item xs={12}  className='mobile-decision-property'>
                                    <div className='mobile-d-property-title'><DecisionStatus status={DECISION_STATUS.UNDECIDED}/></div>
                                    <div>{nrUndecidedDecisions}</div>
                                </Grid>
                                <Divider />
                                <Grid item xs={12}  className='mobile-decision-property'>
                                    <div className='mobile-d-property-title'><DecisionStatus status={DECISION_STATUS.CHANGES_REQUESTED}/></div>
                                    <div>{nrChangesRequested}</div>
                                </Grid>
                                <Divider />
                                <Grid item xs={12}  className='mobile-decision-property'>
                                    <div className='mobile-d-property-title'><DecisionStatus status={DECISION_STATUS.REFUSED}/></div>
                                    <div>{nrRefused}</div>
                                </Grid>
                                <Divider />
                                <Grid item xs={12}  className='mobile-decision-property'>
                                    <div className='mobile-d-property-title'><DecisionStatus status={DECISION_STATUS.APPROVED}/></div>
                                    <div>{nrApprovedDecisions}</div>
                                </Grid>
                            </Card>
                        </Grid>
                    })
                }
            </div>}
           </>
        )
    }
}


export const WithScreenAdminDecisionTypes = ({ history }) => {
    const theme = useTheme();
    const isDesktop =  useMediaQuery(theme.breakpoints.up('md'));
    return  <AdminDecisionTypes isDesktop={isDesktop} history={history} />
}