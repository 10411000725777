import { Button, Card, MenuItem, Select } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react'
import { toast } from 'react-hot-toast';
import Input from '../../components/input/Input';

import * as unitService from '../../services/unitService';
import * as authService from '../../services/authService';
import { getProjectId, USER_ROLES } from '../../utils/utils';

export default class UnitsConfig extends React.Component {
    state = {
        unitId: '',
        name:'',
        ownerId: null,
        phase:'',
        editing: false,
        clients: []
    }

    async componentDidMount() {
        const id = new URLSearchParams(this.props.location.search).get("id");
        const mode =  new URLSearchParams(this.props.location.search).get("mode");

        const clients = await authService.getSpecificUsers(USER_ROLES.CLIENT);
        clients.unshift({firstName : 'Geen', lastName: 'eigenaar', _id: 'xxxxxxxx'})

        this.setState({ clients });

        if (mode == 'edit') {
            const unit = await unitService.getUnitById(id)
            const ownerId = unit.ownerId ? unit.ownerId : 'xxxxxxxx' ;
            this.setState({ unitId: unit._id, name: unit.name, ownerId: ownerId, phase: unit.phase, editing: true })
        }
    }

    saveUnit = async () => {
        const { projectId } = this.props.match.params;
        const { unitId,name, ownerId, phase, editing } = this.state;

        const realOwnerId = ownerId == 'xxxxxxxx' ? null : ownerId;

        try {
            editing ?
                await unitService.updateUnit({_id: unitId, name, ownerId: realOwnerId, phase, projectId}) :
                await unitService.createUnit({ name, ownerId: realOwnerId, phase, projectId})
            toast.success('Succesfully saved unit');
            this.props.history.push(`/manage/projects/${projectId}`);
        } catch (error) {
            toast.error(`Failed to save unit`);
        }
    }    

    render() {
       const { unitId, name, ownerId, phase, clients } = this.state;

        return(
            <Container>
                <h1>Unit configureren</h1>
                <Card>
                    <Input name='Name' label='Unit' required value={name}  onChange={(e) => this.setState({ name: e.target.value })}/>
                    <Input name='Phase' label='Fase' required value={phase}  onChange={(e) => this.setState({ phase: e.target.value })}/>
                    
                    <Input select value={ownerId} label='Eigenaar' onChange={(e) => this.setState({ ownerId: e.target.value })}
                        children={clients.map(c => <MenuItem value={c._id}>{`${c.firstName} ${c.lastName}`}</MenuItem>)}
                    />
                    <Button sx={{ marginTop: '16px'}} onClick={() => { this.saveUnit() }}>Unit opslaan</Button>
                </Card>
               
            </Container>
        )
    }
}
