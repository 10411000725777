import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem } from '@mui/material'
import toast from 'react-hot-toast';
import React from 'react'

import * as partnerService from '../../services/partnerService';
import Input from '../input/Input';

export default class PartnerRightsDialog extends React.Component {
    state = {
        partners: [],
        selectedPartner: null,
        selectedDecisionIds: [],
    }


    async componentDidMount() {
        const partners = await partnerService.getPartners();
        this.setState({ partners });
    }

    selectPartner = (partner) => {
        this.setState({ selectedPartner: partner });
        const { rights } = partner;
        const { decisionType } = this.props;

        const decisionRights = rights.find(r => r.decisionTypeId == decisionType._id);
        if (!decisionRights) {
            this.setState({ selectedDecisionIds: [] });
        } else {
            this.setState({ selectedDecisionIds: decisionRights.decisionIds })
        }
    }

    handleDecisionIdChange = (decisionId) => {
        const { selectedDecisionIds } = this.state;
        selectedDecisionIds.includes(decisionId) ?
            this.setState({ selectedDecisionIds: selectedDecisionIds.filter(id => id != decisionId )}) :
            this.setState({ selectedDecisionIds: [...selectedDecisionIds, decisionId ]});
    }
    
    saveRights = async () => {
        const { decisionType } = this.props;
        const { selectedPartner, selectedDecisionIds } = this.state;
        
        try {
            await partnerService.upsertRights({ decisionTypeId: decisionType._id, partnerId: selectedPartner._id, decisionIds: selectedDecisionIds });
            toast.success('Rechten succesvol gewijzigd!')
            this.props.onClose();
        } catch (error) {
            console.log(error);
            toast.error('Kon rechten niet wijzigen');
        }
    }

    render() {
        const { partners, selectedDecisionIds } = this.state;
        const { decisions, decisionType } = this.props;

        return(
          <Dialog open={true} maxWidth='md'scroll='paper' onClose={() => this.props.onClose()}>
            <p style={{ width: '600px'}}></p>
            <DialogTitle>Partner rechten - {decisionType.title}</DialogTitle>
            <Divider />
            <DialogContent>
            <Input label='Partner' select onChange={(e) => this.selectPartner(e.target.value)}
                children={partners.map(p => <MenuItem value={p}>{p.name}</MenuItem>)} />
                <p>Beslissingen: </p>
                {decisions.map(d => <div>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox checked={selectedDecisionIds.includes(d._id)} onChange={() => this.handleDecisionIdChange(d._id)}/>}
                        label={d.unitId.name}
                        labelPlacement="end"
                    />
                </div>)}
            </DialogContent>
            <Button onClick={() => this.saveRights()}>Rechten opslaan</Button>
          </Dialog>
        )
    }
}
