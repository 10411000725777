import React from 'react'
import * as dayjs from 'dayjs';
import {  Chip, Grid } from '@mui/material';
import { DECISION_STATUS } from '../../utils/utils';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

import './decision-preview.scss';

export default class DecisionPreview extends React.Component {


    render() {
        const { _id, status, deadline, decisionTypeId, history } = this.props;


        return(
            <div className='decision-preview-container' onClick={() => history.push(`/decisions/${_id}`)}>
                <Grid container>
                    <Grid item xs={7} className='d-p-title'>
                        {decisionTypeId && decisionTypeId.title}
                    </Grid>
                    <Grid item xs={5} className='d-p-status-container'>
                        {deadline && <Chip icon={<AccessAlarmIcon />} className={`decision-preview-status-chip chip-deadline`} 
                            label={`Deadline: ${dayjs(deadline).format('D MMMM YYYY')}`} />}
                    </Grid>
                </Grid>
            </div>
        )
    }
}
