import React from 'react'
import { toast } from 'react-hot-toast';
import { Button, Grid } from '@mui/material';
import '../styles/login.scss';

import * as authService from '../services/authService';
import Input from '../components/input/Input';

import banner from '../assets/havis_banner.jpeg';
import logo from '../assets/black_logo.png';

export default class ResetPasswordInit extends React.Component {
    state = {
     email: '',
     resetStarted: false,
    }

    resetPasswordInit = async (e) => {
        e.preventDefault();
        try {
            const { email } = this.state;
            await authService.resetPasswordInit({ email })
            this.setState({resetStarted: true})
        } catch (error) {
            toast.error(`Er ging iets mis`);
        }
    }

    render() {
        const { resetStarted, email } = this.state;
        return(
            <div  className='login-container'>
                    <img src={logo} className='login-logo-img' />
                    <h1 className='text-center'>Wachtwoord resetten</h1>
                    <form method='post' onSubmit={this.resetPasswordInit} className='login-form'>
                        <Input name='Password' type='email'  label='Email' required  onChange={(e) => this.setState({ email: e.target.value })}/>
                        {resetStarted && <p>Er is een e-mail verzonden naar <b>{email}</b> met instructies </p>}
                        <Button type='submit' fullWidth sx={{ marginTop: '12px'}}>Wachtwoord resetten</Button>
                    </form>
            </div>
           
        )
    }
}
