import { Container, Grid } from '@mui/material'
import React from 'react'
import dayjs from 'dayjs';
import * as proposalService from '../../services/proposalService.js'
import * as projectService from '../../services/projectService';

import '../../styles/proposal-action-overview.scss';
import { getFileUrl } from '../../utils/utils.js';

export default class ConfigOverview extends React.Component {
    state = {
        projectName: '',
        projectFiles: [],
        proposalActions : []
     }
 
     async componentDidMount() {
        const { projectId } =  this.props.match.params;
        const project = await projectService.getProject(projectId);
        this.setState({ projectName: project.name, projectFiles: project.files })
        const proposalActions = await proposalService.getProposalActionsOfProject(projectId);
        this.setState({ proposalActions });

        console.log('actions', proposalActions)
       
     }
    render() {
        const { proposalActions, projectFiles, projectName } = this.state;
        return(
            <Container>
                <Grid container columnGap={1}>
                    <Grid>
                        {projectFiles && projectFiles.length > 0 && <img style={{ maxHeight: '100px', borderRadius: '16px'}} src={getFileUrl(projectFiles[0])} />}
                    </Grid>
                    <Grid>
                        <h1>{projectName}</h1>
                    </Grid>
                </Grid>
                {proposalActions.length > 0 && <div class="manual-wrapper">
                    <p class="manual-column text-weight-bold">Beslissing</p>
                    <p class="manual-column text-weight-bold">Actie</p>
                    <p class="manual-column text-weight-bold">Unit</p>
                    <p class="manual-column text-weight-bold">Naam</p>
                    <p class="manual-column text-weight-bold">Datum</p>
                </div>}
                
                {proposalActions.map(p => {
                    if (p.proposalId != null && p.userId != null && p.proposalId.unitId != null &&  p.proposalId.decisionId != null && p.proposalId.decisionId.decisionTypeId != null){
                        return <div class="manual-wrapper">
                                    <p class="manual-column"> {p.proposalId.decisionId.decisionTypeId.title} </p>
                                    <p class="manual-column"> {p.actionType == 'Approved' ? 'Geaccepteerd': ''} </p>
                                    <p class="manual-column"> {p.proposalId.unitId.name} </p>
                                    <p class="manual-column"> {p.userId.lastName + ' ' + p.userId.firstName} </p>
                                    <p class="manual-column"> {dayjs(p.signDate).format('DD-MM-YYYY H:mm')} </p>
                                </div>
                    }
                })}

                {proposalActions.length == 0 && <h2>Er zijn nog geen proposal logs aanwezig.</h2>}

            </Container>
        )
    }
}
