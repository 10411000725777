import {  Button, Card, Container, Grid } from '@mui/material'
import React from 'react'

import * as partnerService from '../../services/partnerService';

export default class PartnersOverview extends React.Component {
    state = {
        partners: []
    }

    async componentDidMount() {
        const partners = await partnerService.getPartners();
        this.setState({ partners });
    }

    render() {
        const { partners } = this.state;
        return(
            <Container>
               <h1>Partners</h1>
               <Button onClick={() => this.props.history.push('/manage/partners/config')}>Partner toevoegen</Button>
               <Grid container rowSpacing={1}>
                    {partners.map(p => <Grid item xs={12}>
                        <Card sx={{ cursor: 'pointer'}} onClick={() => this.props.history.push(`/manage/partners/config?mode=edit&id=${p._id}`)}>
                        {p.name}
                    </Card>
                    </Grid>)}
               </Grid>
            </Container>
        )
    }
}
