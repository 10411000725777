import React from 'react'
import { Button, Container, Menu, MenuItem } from '@mui/material';
import { toast } from 'react-hot-toast';
import Input from '../../components/input/Input';
import {  getProjectId } from '../../utils/utils'
import * as articleService from '../../services/newsArticleService';
import * as unitService from '../../services/unitService';
import * as decisionService from '../../services/decisionService';
import dayjs from 'dayjs';

export default class DecisionConfig extends React.Component {
    state = {
        selectedUnitId: '',
        budget: 0,
        deadline: '',
        description:'',
        editing: false,
        units: [],
        _id: '',
    }
        
    


    async componentDidMount() {
        const id = new URLSearchParams(this.props.location.search).get("id");
        const mode =  new URLSearchParams(this.props.location.search).get("mode");
        const { decisionTypeId } = this.props.match.params;
        
        if (mode != 'edit'){
            const decisionType = await decisionService.getDecisionType(decisionTypeId);
            if (decisionType.description) this.setState({description: decisionType.description});
        }
        const units = await unitService.getUnitsOfProject(getProjectId());
        this.setState({ units });

        if (mode == 'edit') {
            const { decision } = await decisionService.getDecision(id);
            this.setState({
                _id: decision._id, 
                selectedUnitId: decision.unitId && decision.unitId._id,
                budget: decision.budget,
                deadline: dayjs(decision.deadline).format('YYYY-MM-DD'),
                description: decision.description,
                editing: true })
        }

        
        
    }

    saveDecision = async (e) => {
        e.preventDefault();
        const { selectedUnitId, budget, deadline, editing, _id, description } = this.state;
        const { decisionTypeId } = this.props.match.params;
        
        const decision = { unitId: selectedUnitId, budget, deadline, projectId: getProjectId(), description };
        try {
            editing ?
               await decisionService.updateDecision(decisionTypeId, _id ,decision) :
               await decisionService.createDecision(decisionTypeId, decision);

            toast.success('Beslissing opgeslagen');
            this.props.history.push(`/decisions/type/${decisionTypeId}`);
        } catch (error) {
            console.log(error);
            toast.error(`Kon beslissing niet opslaan`);
        }
    }    

    render() {
       const { deadline, units, selectedUnitId, budget, description } = this.state;
        return(
            <Container>
                <h1>Beslissing configureren</h1>
                <form method='post' onSubmit={this.saveDecision}>
                    <Input required label='Unit' value={selectedUnitId} onChange={(e) => this.setState({ selectedUnitId: e.target.value })}
                        select children={units.map(u => <MenuItem value={u._id}>{u.name}</MenuItem>)}/>
                    <Input required label='Budget' type='number' value={budget} onChange={(e) => this.setState({ budget: e.target.value })}/>
                    <Input required label='Deadline' value={deadline} type='date' onChange={(e) => this.setState({ deadline: e.target.value })}/>
                    <Input label='Beschrijving' value={description} onChange={(e) => this.setState({ description: e.target.value })}/>
                    <Button type='submit'>Beslissing opslaan</Button>
                </form>
            </Container>
        )
    }
}
