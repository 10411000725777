import { Divider, Drawer, useMediaQuery } from "@mui/material";
import logo from '../../assets/havis_logo_small.png';

import './sidebar.scss';
import ProjectSwitcher from "../project-switcher/ProjectSwitcher";
import { getFileUrl, getFileUrlFromId, getProjectId, getRole, USER_ROLES } from "../../utils/utils";
import Icon from "../icon/Icon";

const Sidebar = (props) => {
    const isOpen = props.isOpen;
    const projects = props.projects
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
      });

    const navIcons = {
      DASHBOARD: <Icon i='dashboard' className='nav-pill-icon' />,
      CHAT: <Icon i='chat' className='nav-pill-icon'/>,
      DOCUMENT: <Icon i='folder' className='nav-pill-icon' />,
      DECISION: <Icon i ='check_box' className='nav-pill-icon' />,
      NEWS: <Icon i='feed' className='nav-pill-icon' />,
      CONFIG: <Icon i='settings' className='nav-pill-icon' />
    }

    const navItems = [
      { text: 'Dashboard', link: `/dashboard`, icon: navIcons.DASHBOARD, hasAccess: [USER_ROLES.CLIENT] },
      { text: 'Gesprekken', link: `/conversations`, icon: navIcons.CHAT, hasAccess: [USER_ROLES.CLIENT, USER_ROLES.SUPPLIER, USER_ROLES.SUPER_ADMIN]},
      { text: 'Documenten', link: `/documents`, icon: navIcons.DOCUMENT, hasAccess: [USER_ROLES.CLIENT, USER_ROLES.TECHNICAL, USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPPLIER] },
      { text: 'Beslissingen', link: `/decisions`, icon: navIcons.DECISION, hasAccess: [USER_ROLES.CLIENT, USER_ROLES.SUPPLIER, USER_ROLES.SUPER_ADMIN]},
      { text: 'Nieuws', link: `/news`, icon: navIcons.NEWS, hasAccess: [USER_ROLES.CLIENT, USER_ROLES.SUPER_ADMIN] },
      { text: 'Configuratie', link: `/manage`, icon: navIcons.CONFIG, bottom: true, hasAccess: [USER_ROLES.SUPER_ADMIN]}
    ]

    const role = getRole();
    const projectId = getProjectId();
    const currentPath = props.location.pathname;

    const project = projects.find(p => p._id == projectId);

    const content = (
        <div className='sidebar-content'>
            <div className='logo-container'>
            {project && project.files && project.files.length > 0 &&<img src={getFileUrl(project.files[0])} />}
            {project && project.files && !project.files.length && <img className="projects-image" src={logo}/>}
            </div>
            {props.projects && props.projects.length > 0 && <ProjectSwitcher projects={projects} />}
            <div className='navigation-container'>
                {navItems.filter(i => i.hasAccess.includes(role)).map(i => <div 
                  className={`nav-pill  ${(currentPath.includes(i.link) && 'nav-pill-active')} ${(i.bottom && 'nav-bottom')}`} 
                  onClick={() => props.history.push(i.link)}>
                    {i.icon}
                  <span className='nav-pill-text'>{i.text}</span>
                </div>)}

            </div>
        </div>
    )


    if (lgUp) {
        return (
          <Drawer
            anchor="left"
            open={isOpen}
            PaperProps={{
              sx: {
                width: '230px',
                borderRight: 'none !important',
                boxShadow: '0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0px 0px 0px 0px rgb(10 10 10 / 2%) !important'
              }
            }}
            variant="permanent"
          >
            {content}
          </Drawer>
        );
      }
    
      return (
        <Drawer
          anchor="left"
          onClose={() => props.close()}
          open={isOpen}
          PaperProps={{
            sx: {
              backgroundColor: 'neutral.900',
              width: 230,
            }
          }}
          sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
          variant="temporary"
        >
          {content}
        </Drawer>
      );  
}

export default Sidebar;