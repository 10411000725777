import React from "react";
import { Switch } from "react-router-dom";

import UserOverview from "./UserOverview";
import UnitsConfig from "./UnitsConfig";
import CustomRoute from "../../components/custom-route/CustomRoute";
import { Container } from "@mui/system";
import Projects from "./Projects";
import ProjectDetails from "./ProjectDetails";
import ProjectConfig from "./ProjectConfig";
import ConfigOverview from "./ConfigOverview";
import PartnersOverview from "./PartnersOverview";
import PartnerConfig from "./PartnerConfig";




export default class ConfigOverviewRoutes extends React.Component {
  state = {
    isOpen: false,
  }

  render() {
    return (
      <Container>
        <Switch>
            <CustomRoute exact path='/manage/' component={ConfigOverview} />

            <CustomRoute exact path='/manage/projects/' component={Projects} />
            <CustomRoute exact path='/manage/projects/:projectId/' component={ProjectDetails} />
            <CustomRoute exact path='/manage/projects/configure/config' component={ProjectConfig} />
            <CustomRoute exact path='/manage/projects/:projectId/units/config' component={UnitsConfig} />

            <CustomRoute exact path='/manage/users' component={UserOverview} />

            <CustomRoute exact path='/manage/partners' component={PartnersOverview} />
            <CustomRoute exact path='/manage/partners/config' component={PartnerConfig} />

        </Switch>
      </Container>
    );
  }
}
