import { httpClient, s3Client } from "./index.js";

export const createNewsArticle = async (articleId, name, content, files) => {
    const response = await httpClient.post(`article/`,  articleId, name, content, files );
    return response.data;
}

export const getNewsArticles = async (projectId) => {
    const response = await httpClient.get(`article/${projectId}`);
    return response.data;
}

export const getNewsArticleById = async (articleId) => {
    const response = await httpClient.get(`article/details/${articleId}`);
    return response.data;
}

export const updateNewsArticle = async (articleId, name, content, files) => {
    const response = await httpClient.put(`article/`,  articleId, name, content, files );
    return response.data;
}

export const getDashboardNewsArticles = async (projectId) => {
    const response = await httpClient.get(`article/dashboardArticles/${projectId}`);
    return response.data;
}