import React from 'react'
import { DECISION_STATUS } from '../../utils/utils';
import Icon from '../icon/Icon';
import './decision-status.scss';

export default class DecisionStatus extends React.Component {


    render() {
        const { status } = this.props;
        let icon = '';
        let cssClass = '';

        if (status == DECISION_STATUS.APPROVED) {
            icon = 'check';
            cssClass = 'ds-approved'
        } 

        if (status == DECISION_STATUS.UNDECIDED) {
            // icon = 'unknown_document';
        }

        if (status == DECISION_STATUS.CHANGES_REQUESTED) {
            icon = 'build';
            cssClass = 'ds-changes'
        }

        if (status == DECISION_STATUS.REFUSED) {
            icon = 'close';
            cssClass = 'ds-refused';
        }

        return(
            <div className={`decision-status ${cssClass}`}>
                <Icon i={icon}  className='decisionstatus-icon' />
                {status == DECISION_STATUS.APPROVED && 'Goedgekeurd'}
                {status == DECISION_STATUS.UNDECIDED && 'Onbeslist'}
                {status == DECISION_STATUS.CHANGES_REQUESTED && 'Wijzigingen gevraagd'}
                {status == DECISION_STATUS.REFUSED && 'Geweigerd'}
            </div>
        )
    }
}
