import React from 'react'
import { toast } from 'react-hot-toast';
import { Button, Grid } from '@mui/material';
import '../styles/login.scss';

import * as authService from '../services/authService';
import Input from '../components/input/Input';

import banner from '../assets/havis_banner.jpeg';
import logo from '../assets/havis_logo.png';

export default class PasswordConfig extends React.Component {
    state = {
     password: '',
     repeatPassword: '',
     token: '',
     userId: '',
     errorMessage: '',  
    }

    componentDidMount() {
        const token = new URLSearchParams(this.props.location.search).get("token");
        const userId =  new URLSearchParams(this.props.location.search).get("user");
        this.setState({ token, userId })
    }

    setPassword = async (e) => {
        e.preventDefault();
        try {
            const { password, repeatPassword, token, userId } = this.state;
            const passwordMatch = password == repeatPassword;
            if (!passwordMatch) return this.setState({ errorMessage: 'Wachtwoord komt niet overeen' });
            if (password.length < 4) return this.setState({ errorMessage: 'Wachtwoord moet minstens 4 karakters lang zijn.' });

            await authService.setPassword({ password, token, userId });
            toast.success('Wachtwoord succesvol ingesteld!');
            this.props.history.push('/login')
        } catch (error) {
            toast.error(`Er ging iets mis`);
        }
       
    }

    render() {
        const { errorMessage } = this.state;
        return(
            <div  className='login-container'>
                    <img src={logo} className='login-logo-img' />
                    <h1 className='text-center'>Wachtwoord instellen</h1>
                    <form method='post' onSubmit={this.setPassword} className='login-form'>
                        <Input name='Password' type='password' label='Wachtwoord' required  onChange={(e) => this.setState({ password: e.target.value, errorMessage: '' })}/>
                        <Input name='Repeat password' type='password' label='Herhaal wachtwoord' required  onChange={(e) => this.setState({ repeatPassword: e.target.value, errorMessage: '' })}/>
                        <Button type='submit' fullWidth sx={{ marginTop: '12px'}}>Wachtwoord instellen</Button>
                        <p className='login-error-message'>{errorMessage}</p>
                    </form>
    
        
            </div>
           
        )
    }
}
