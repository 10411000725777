import { httpClient } from "./index.js";
import { getProjectId } from "../utils/utils.js";

export const inviteContact = async (data) => {
  try {
    const response = await httpClient.post(`auth/invite/`, data);
    const responseData = response && response.data;

    return responseData;
  } catch (e) {
    throw Error(e.response.data);
  }
};

export const login = async (email, password) => {
  const response = await httpClient.post(`auth/login/`, { email, password });
  const responseData = response && response.data;

  localStorage.setItem("jwt-token", responseData.token);
  localStorage.setItem("role", responseData.user.role);

  const { projects } = responseData;
  if (projects.length > 0 && !getProjectId())  localStorage.setItem('projectId', projects[0]._id ) 
  return responseData;
};


export const setPassword = async (data) => {
  const response = await httpClient.post(`auth/password`, data);
  return response && response.data;
};

export const resetPasswordInit = async (data) => {
  const response = await httpClient.post(`auth/reset-password/init`, data);
  return response && response.data;
};


export const getUsers = async () => {
  const response = await httpClient.get(`auth/`);
  return response.data.users;
}

export const getUseryId = async (userId) => {
  const response = await httpClient.get(`auth/${userId}`);
  return response.data;
}

export const getMe = async () => {
  const response = await httpClient.get(`auth/info/me`);
  return response.data;
}

export const getSpecificUsers = async (userType) => {
  const response = await httpClient.get(`auth/users/${userType}`);
  return response.data;
}

export const impersonateUser = async (userId) => {
  const response = await httpClient.post(`auth/impersonate/${userId}`);
  return response.data;
}

export const resendInviteEmail = async (data) => {
  const response = await httpClient.post(`auth/resendInviteEmail`, data);
  return response;
};

export const updateUser = async (userId, user) => {
  const response = await httpClient.put(`auth/${userId}`, user);
  return response && response.data;
}

export const setUserInActive = async (userId) => {
  const response = await httpClient.post(`auth/${userId}/inactive`);
  return response && response.data;
};

export const setUserActive = async (userId) => {
  const response = await httpClient.post(`auth/${userId}/active`);
  return response && response.data;
};