import { Avatar, Card, Popover, Popper } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react'
import { getRole, USER_ROLES } from '../../utils/utils';
import Icon from '../icon/Icon';
import './message.scss';

export default class Message extends React.Component {

    state = {
        anchor: null
    }

     handleReadToggle = (event) => {
        this.state.anchor ? this.setState({ anchor: null }) : this.setState({ anchor: event.currentTarget});
      };

    render() {
        const { content, sender, senderId, timestamp, userId, read } = this.props;

        const personRead = read.filter(r => dayjs(r.timestamp).isAfter(timestamp));
        const personUnread = read.filter(r => !dayjs(r.timestamp).isAfter(timestamp));

        return (
            <div className='message-container'>
                <div className={`message-wrapper ${userId == senderId && 'message-wrapper-sender'}`}>
                    <Avatar sx={{ padding: '6px'}} children={sender.split(' ').map(w => w.charAt(0))} />
                    <div className='message-content-container'>
                        <div className='message-content'>
                            <div className='message-sender'>
                                {sender}
                                {getRole() == USER_ROLES.SUPER_ADMIN &&<Icon i='info' onClick={(e) => this.handleReadToggle(e)} />}
                            </div>
                            <p className='message-text'>{content}</p>
                        </div>
                        <p className='message-timestamp' style={userId == senderId ? { textAlign: 'end'} : {}}>{dayjs(timestamp).format('D MMMM')}</p>
                    </div>
                    
                </div>
                <Popper open={Boolean(this.state.anchor)} anchorEl={this.state.anchor}>
                    <Card>
                        {read.map(r => {
                            const hasRead = dayjs(r.timestamp).isAfter(timestamp);
                            return <div className={`read-info-container ${!hasRead && 'message-unread'}`}>
                                <Icon i={hasRead ? 'visibility' : 'visibility_off'} /> 
                                {r.name}
                            </div>
                        })}
                    </Card>
                </Popper>
            </div>
        )
    }
}
