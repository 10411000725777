import React from 'react'
import { Button, Card, Container } from '@mui/material';

import * as conversationService from '../services/conversationService';
import * as userService from '../services/authService.js'
import ConversationItem from '../components/conversation-item/ConversationItem';
import { getProjectId, getRole, USER_ROLES } from '../utils/utils';
import Icon from '../components/icon/Icon';



export default class Conversations extends React.Component {
    state = {
       conversations: [],
       userId: ''
    }

    async componentDidMount() {
      const me = await userService.getMe();
      this.setState({userId: me._id})
      const conversations = await conversationService.getMyConversations(getProjectId());
      this.setState({ conversations });
      
    }


    render() {
        const { conversations, userId } = this.state;
        const unitConversations = conversations.filter(c => !c.partnerId);
        const partnerConversations = conversations.filter(c => c.partnerId);

        return(
            <Container>
              <Button startIcon={<Icon i='send' />} sx={{ marginBottom: 2}} onClick={() => this.props.history.push('/new-conversation')}>Nieuw gesprek</Button>
              {unitConversations.map(c => <ConversationItem {...c} userId={userId} history={this.props.history}/>)}
              {partnerConversations.length > 0 && <div>
                {getRole() != USER_ROLES.SUPER_ADMIN ? <h3>Contacteer havis</h3> : <h3>Partner gesprekken</h3>}
                {partnerConversations.map(c => <ConversationItem {...c} userId={userId} history={this.props.history}/>)}
              </div>}
            </Container>
        )
    }
}
