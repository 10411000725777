import React from 'react'
import {  Container } from '@mui/material';
import ClientDecisions from './client/ClientDecisions';
import { WithScreenAdminDecisionTypes } from './admin/AdminDecisionTypes';
import { getRole, USER_ROLES } from '../utils/utils';

export default class Decisions extends React.Component {

    render() {
        const role = getRole();
        return(
            <Container>
                {role == USER_ROLES.CLIENT && <ClientDecisions history={this.props.history}/>}
                {role != USER_ROLES.CLIENT && <WithScreenAdminDecisionTypes history={this.props.history}/>}
            </Container>
        )
    }
}
