import { createTheme } from "@mui/material/styles";

export const MaterialTheme = createTheme({
    typography: {
        fontFamily: ['Manrope']
    },
components: {
// Name of the component
    MuiButton: {
        defaultProps:{
            variant: 'contained',
        },
        styleOverrides: {
        // Name of the slot
            root: {
                // fontSize: "1rem",
                backgroundColor: 'var(--color-primary)',
                color: 'var(--color-white)',
                lineHeight: 'inherit',
                textTransform: 'none',
                letterSpacing: '0.7px',
                fontWeight: '700',
                padding: '10px',
                fontSize: '1rem'
                
            },
            contained: {
                // backgroundColor: "#078C65",
                // color: "#FFFFFF",
            },
            outlined: {
                // marginRight: "12px",
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                borderRadius: '8px',
                padding: '24px',
                margin: '12px 0px',
                // boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                boxShadow: '0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%)'
            }
        }
    },
    MuiContainer: {
        styleOverrides: {
            root: {
                maxWidth: '1380px !important',
            }
        }
    },
    MuiAvatar: {
        styleOverrides: {
            root: {
                backgroundColor: '#b5b5b5 !important',
                padding: '6px',
                textTransform: 'uppercase'
            }
        }
    }
},
});
