import { TextField } from "@mui/material";

export default function Input({ name, label, type = "text", required = false, className = "", hint = null, ...rest }) {
    return (
      <div className={["input", className].join(" ")}>
        <label htmlFor={name} className="input__label">
          {label}
        </label>
        <TextField
          {...rest}
          id={name}
          type={type}
          fullWidth
          required={required}
          name={name}
          className="input__field"
        />
        {hint && <div className="input__hint">{hint}</div>}
      </div>
    );
  }
  