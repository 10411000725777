import React from 'react'
import DescriptionIcon from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';
import { Button, MenuItem, Select } from '@mui/material';

import Icon from '../icon/Icon';

import { s3Client } from '../../services'; 
import './file-upload.scss';
import { FILE_TAGS } from '../../utils/utils';


export default class FileUpload extends React.Component {
    state = {
       files : [],
       progresses : []
    }

    
    handleFilesSelection = (e) => {
        const filesObject = e.target.files;
        const files = Object.keys(filesObject).map((k) => Object.assign(filesObject[k], { index: k, tag: FILE_TAGS.NOTINVOICE }))
        this.props.onChange(files);
        this.setState({ files });
    }

    handleTagChange(index, e){
        const mappedFiles = this.state.files.map(f => (f.index === index ? Object.assign(f, {tag: e}) : f));
        this.setState({ files: mappedFiles });
        this.props.onChange(mappedFiles);
    }

    upload = async (uploadUrls) => {

        const allFilesUploaded = await Promise.all(this.state.files.map(async file => {
            const options = { 
                headers: { 'Content-Type': file.type },
                onUploadProgress: (event) => {
                    this.setState({ files: this.state.files.map(f => f.index == file.index ? Object.assign(f, event) : f)})
                }  
            };
    
            const uploadUrl = uploadUrls.find(url => url.index == file.index);
            if (uploadUrl) return s3Client.put(uploadUrl.uploadUrl, file, options);
    
        }));

        return allFilesUploaded;
        
    }

    render() {
        const { single, fileTypes, disableTags } = this.props
       const { files } = this.state;
        return(
            <div>
                <div className='file-upload-main-container'>
                    <label for='fileUpload' className='upload-label'>
                        <Icon i='add'/>{single ? 'Bestand toevoegen' : 'Bestanden toevoegen'}
                        <input id='fileUpload' type='file' multiple={!single} accept={fileTypes || '*'} onChange={(e) => this.handleFilesSelection(e)}/>
                    </label>
                </div>
                <div>
                            {files.map(f => <div className='file-upload-file-container'>
                                    <div className='file-upload-icon-container'>
                                        <DescriptionIcon className='file-upload-file-icon'/>
                                    </div>
                                    <div className='file-upload-content-container'>
                                        <div className='file-upload-content-wrapper'>
                                            <div>{f.name}</div>
                                            { !disableTags &&
                                                <Select className='file-upload-tagger' value={f.tag} onChange={(e) => this.handleTagChange(f.index, e.target.value)}>
                                                    {Object.values(FILE_TAGS).map(r => <MenuItem value={r}>{r}</MenuItem>)}
                                                </Select>
                                            }
                                            {(f.progress && f.progress !=1) && <div className='file-upload-percentage'>{Math.round(f.progress * 100 )}%</div>}
                                            {(f.progress && f.progress == 1) && <div className='file-upload-percentage'><DoneIcon sx={{ color: '#6e90e8'}}/></div>}
                                        </div>
                                        <div>
                                            {(f.progress && f.progress != 1) && <progress className='file-upload-progress' value={f.progress * 100} max='100'/>}
                                        </div>
                                    </div>
                                </div>)}
                        </div>
            </div>
        )
    }
}
