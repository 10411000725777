import { Button, Card, Modal } from '@mui/material'
import React, { Fragment } from 'react'

import * as fileService from '../../services/fileService';

import pdfIcon from '../../assets/pdf-icon.png';
import './file-preview.scss';
import toast from 'react-hot-toast';
import { ConditionalWrapper, getFileUrl, getRole, USER_ROLES } from '../../utils/utils';
import Icon from '../icon/Icon';


export default class FilePreview extends React.Component {
    state = {
       open: false,
    }

    componentDidMount() {
    }
    
/*     signFile = async (event) => {
        event.stopPropagation();
        await fileService.signFile(this.props._id);
        this.setState({ open: false, signed: true });
        toast.success(`${this.props.name} succesvol ondertekend`);
    } */

    doPrimaryAction = () => {
        const { file } = this.props;
        const isPdf = file.type.includes('pdf');
        if (isPdf) {
            this.setState({ open : true })
        } else {
            this.downloadFile();
        }
    }

    downloadFile = (e) => {
        e.stopPropagation();
        fileService.downloadFile(this.props.file)
    }

    render() {
        const {file, small, showSigned, onDelete, isDelete} = this.props
        const { name, _id, type, signed } = file;
        const { open } = this.state;
        const isPdf = type.includes('pdf');

        return(
            <>
                <ConditionalWrapper
                    condition={this.props.card}
                    wrapper={children => <Card sx={{ padding: '0px', overflow: 'unset'}}>{children}</Card>}
                >
                    <div className='file-preview-container' onClick={() => this.doPrimaryAction()}>
                        {!small && <div className='file-preview-header-container'>
                            {!isPdf && <Icon i='imagesmode' />}
                            {isPdf && <Icon i='draft' />}
                        </div>}
                        <div className='file-preview-info-container'>
                            <div className='file-preview-name'>
                                {name}
                            </div>
                        </div>
                        <div className='file-preview-download-container'>
                            <div className='file-preview-download-content-container'>
                                <Icon i='download' onClick={(e) => this.downloadFile(e)}/>
                                {(getRole() == USER_ROLES.SUPER_ADMIN && isDelete) && <Icon i='delete' onClick={(e) => onDelete(e)}/>}
                            </div>
                        </div>
                    </div>
                    {((showSigned && file.signed)) && <div className='file-tags-wrapper'>
                        <div className='file-signed'>Ondertekend</div>
                    </div>}
                </ConditionalWrapper>
                {isPdf && <Modal open={open}>
                    <div className='file-preview-viewer' onClick={() => this.setState({ open : false })}>
                        <embed className='pdf-previewer' src={getFileUrl(file)} width="500" height="375" type="application/pdf"></embed>
                    </div>
                </Modal>}
                
            </>
        )
    }
}
