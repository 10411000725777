import React from 'react'
import { Avatar, Button, Card, Container, Divider, Grid, TextareaAutosize, TextField } from '@mui/material';

import * as conversationService from '../services/conversationService';
import { Box } from '@mui/system';
import Message from '../components/message/Message';

import '../styles/conversation.scss';
import FileUpload from '../components/file-upload/FileUpload';
import FilePreview from '../components/file-preview/FilePreview';
import { mapFileProps, USER_ROLES } from '../utils/utils';
import toast from 'react-hot-toast';

export default class Conversation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            message: '',
            messages: [],
            decision: {
                decisionTypeId: {
                    title: 'Algemeen onderwerp',
                }
            },
            unitId: {
                name: '',
                ownerId: {
                    firstName: '',
                    lastName: '',
                }
            },
            files: [],
            filesToUpload: [],
            read: [],
            partners: [],
            partnerId: null,
        }

        this.uploader = React.createRef();
        this.scrollRef = React.createRef();
    }


    async componentDidMount() {
        const { conversationId } = this.props.match.params;
        const conversation = await conversationService.getConversationDetails(conversationId);
        this.setState({ 
            messages: conversation.messages,
            unitId: conversation.unitId,
            partners: conversation.partners,
            decision: conversation.decisionId != 'GENERAL' ? conversation.decisionId : { decisionTypeId: { title: 'Algemeen onderwerp' }},
            files: conversation.files,
            partnerId: conversation.partnerId,
            userId: conversation.userId,
            read: conversation.read
         }, () => this.scrollToBottom())
    }

     scrollToBottom = (smooth) => {
  
        smooth ? this.scrollRef?.current?.scrollIntoView({ behavior: 'smooth'}) :  this.scrollRef?.current?.scrollIntoView()
      }
    
    sendMessage = async () => {
        const { conversationId } = this.props.match.params;

        const message = await conversationService.sendMessage(conversationId, this.state.message)
        this.setState({ message: '', messages: [...this.state.messages, message] }, () => this.scrollToBottom(true))
    }

    uploadAttachments = async () => {
        const { filesToUpload: files } = this.state;
        const { conversationId } = this.props.match.params;

        try {
            const { uploadUrls } = await conversationService.sendAttachments(conversationId, mapFileProps(files));
            await this.uploader.current.upload(uploadUrls);
            toast.success(files.length > 1 ? 'Bestanden geüpload!' : 'Bestand geüpload!')
            window.location.reload();
        } catch (error) {
            toast.error('Er ging iets mis. Probeer het later opnieuw')
        }
    }

    render() {
        const {  messages, message, files, partners, partnerId, filesToUpload, userId, read } = this.state;
        const title = this.state?.decision?.decisionTypeId?.title;
        const unitName = this.state?.unitId?.name;
        const ownerId = this.state?.unitId?.ownerId;
        const { firstName, lastName } = ownerId || { firstName: '', lastName: '' };

        return(
            <Container>
              <Grid container sx={{  height: '90%' }} columnGap={2}>
                <Grid item md={7} xs={12} sx={{ height: '100%'}}>
                    <Card sx={{ height: '100%'}} className='conversation-container'>
                        <div className='conversation-info-container'>
                            <span className='conversation-details-title'>{title}</span>
                        </div>
                        <div className='conversation-messages-container'>
                            {messages.map(m => <Message {...m} userId={userId} read={read} />)}
                            <div ref={this.scrollRef}></div>

                        </div>

                        <div className='conversation-controls-container'>
                            <TextField className='conversation-input' multiline={true} minRows={3}
                                value={message} onChange={(e) => this.setState({ message: e.target.value })}  />
                            <Button onClick={() => this.sendMessage()}>Verstuur</Button>
                        </div>

                    </Card>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Card>
                        <Box>
                            {!partnerId && <div className='conversation-participant-container'>
                                <div className='conversation-participant-entity'>
                                    {unitName}
                                </div>
                                <div className='conversation-entity-participants'>
                                    <div className='conversation-participant'>
                                        <Avatar children={`${firstName} ${lastName}`.split(' ').map(w => w.charAt(0))}/>
                                        <p>{`${firstName} ${lastName}`}</p>
                                    </div>
                                </div>
                            </div>}
                            <div className='conversation-participant-container'>
                                <div className='conversation-participant-entity'>
                                    Havis
                                </div>
                                <div className='conversation-entity-participants'>
                                    <div className='conversation-participant'>
                                        <Avatar children={`Stephanie Audoor`.split(' ').map(w => w.charAt(0))}/>
                                        <p>{`Stephanie Audoor`}</p>
                                    </div>
                                    <div className='conversation-participant'>
                                        <Avatar children={`Michiel Dierick`.split(' ').map(w => w.charAt(0))}/>
                                        <p>{`Michiel Dierick`}</p>
                                    </div>
                                </div>
                            </div>
                            {partners.map(p => <div className='conversation-participant-container'>
                                <div className='conversation-participant-entity'>
                                    {p.name}
                                </div>
                                <div className='conversation-entity-participants'>
                                    {p.userIds.filter(u => u.role != USER_ROLES.TECHNICAL).map(u => <div className='conversation-participant'>
                                        <Avatar children={`${u.firstName} ${u.lastName}`.split(' ').map(w => w.charAt(0))}/>
                                        <p>{`${u.firstName} ${u.lastName}`}</p>
                                    </div>)}
                                </div>
                            </div>)}
                        </Box>
                    </Card>
                    <Card>
                        <FileUpload single disableTags ref={this.uploader} onChange={(files) => this.setState({ filesToUpload: files })}/>
                        <Button sx={{ marginTop: '12px'}} disabled={filesToUpload.length == 0} onClick={() => this.uploadAttachments()}>Upload</Button>
                    </Card>
                    {files.length > 0 && <Card>
                        <div>Bijlagen</div>
                        <Divider />
                        {files.map(f => <FilePreview small file = {f} />)}
                    </Card>}
                </Grid>
              </Grid>
            </Container>
        )
    }
}
