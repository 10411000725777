import React from 'react';
import { ThemeProvider } from '@mui/material';
import { MaterialTheme } from './MaterialTheme';

import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={MaterialTheme}>
      <App />
    </ThemeProvider>
);
