import React from "react";

import * as projectService from '../../services/projectService';

import { Container } from "@mui/system";
import UnitsOverview from "../config/UnitsOverview";
import { Button, Grid } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { getFileUrl } from "../../utils/utils";
import Icon from '../../components/icon/Icon';


export default class ProjectDetails extends React.Component {
  state = {
    name: '',
    location: '',
    files: []
  }

  async componentDidMount() {
    const { projectId } = this.props.match.params;
    const project = await projectService.getProject(projectId);
    this.setState({ name: project.name, files: project.files })
  }

  render() {
    const { name, files } = this.state;
    const { projectId } = this.props.match.params
    return (
      <Container>
        
        <Grid container columnGap={1}>
          <Grid>
            {files && files.length > 0 && <img style={{ maxHeight: '100px', borderRadius: '16px'}} src={getFileUrl(files[0])} />}
          </Grid>
          <Grid>
            <h1>{name}</h1>
          </Grid>
        </Grid>
        <Button startIcon={<EditIcon />} onClick={() => this.props.history.push(`/manage/projects/configure/config?mode=edit&id=${projectId}`)}>Wijzig project</Button>
        <Button startIcon={<Icon i='info' />} onClick={() => this.props.history.push(`/actions/${projectId}`)}>beslissing logs van project bekijken</Button>
        <UnitsOverview projectId={projectId} history={this.props.history} />
      </Container>
    );
  }
}
