import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel } from '@mui/material'
import Input from '../input/Input';
import toast from 'react-hot-toast';
import React from 'react'

import * as proposalService from '../../services/proposalService';
import { DECISION_STATUS } from '../../utils/utils';

import './sign-proposal-dialog.scss';

export default class SignProposalDialog extends React.Component {
    state = {
        approvedFileIds: [],
        agreeInputOk: false,
    }

    componentDidMount() { 
        const { files } = this.props;
        this.setState({ approvedFileIds: files.filter(f => f.signed).map(f => f._id)})
    }
    
    signProposal = async (proposalId) => {
        try {
            await proposalService.takeProposalAction(proposalId, DECISION_STATUS.APPROVED);
            toast.success('Proficiat! Voorstel goedgekeurd!')
            this.props.onClose();
            window.location.reload();
        } catch (error) {
            const errMessage = error?.response?.data?.error
            toast.error(`Er ging iets mis... ${errMessage}`);
        }
    }

    handleFileIdChange = (fileId) => {
        const { approvedFileIds } = this.state;
        approvedFileIds.includes(fileId) ?
            this.setState({ approvedFileIds: approvedFileIds.filter(id => id != fileId )}) :
            this.setState({ approvedFileIds: [...approvedFileIds, fileId ]});
    }

    handleApproveInput = (e) => {
        const input = e.target.value;
        const dateRegex = /\b\d{1,2}[\/.-]\d{1,2}[\/.-]\d{4}\b/;
        const { name } = this.props

        const hasDate = dateRegex.test(input);
        const hasName = input.toLowerCase().includes(name.toLowerCase());

        console.log(hasDate, hasName);
 
        if (hasDate && hasName) {
            this.setState({ agreeInputOk: true })
        }  else {
            this.setState({ agreeInputOk: false })
        }
    }

    render() {
        const { approvedFileIds, agreeInputOk } = this.state;
        const { files, price, title, _id } = this.props;

        return(
          <Dialog open={true} maxWidth='md'scroll='paper' onClose={() => this.props.onClose()}>
            <DialogTitle>Voorstel ondertekenen</DialogTitle>
            <Divider />
            <DialogContent>
                <div className='sign-p-title'>Voorstel: {title}</div>
                <div>Prijs: € {price}</div>
                <div>Documenten (<u>aanvinken voor akkoord</u>): </div>
                {files.map(f => <div>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox checked={approvedFileIds.includes(f._id)} onChange={() => this.handleFileIdChange(f._id)}/>}
                        label={f.name}
                        labelPlacement="end"
                    />
                </div>)}
                <Input label='Voor akkoord' placeholder='John Doe 22-03-2023' onChange={(e) => this.handleApproveInput(e)}/>
                <div className='sign-p-controls-container'>
                    <div>Voor akkoord: </div>
                    <div>Vink alle documenten aan die u goedkeurt</div>
                    <div>En voer uw naam + datum ondertekening in</div>
                    <div>Bv. John Doe 19-07-2023</div>
                    <Button disabled={!((approvedFileIds.length == files.length) && agreeInputOk)} onClick={() => this.signProposal(_id)}>Voorstel ondertekenen</Button>
                </div>
            </DialogContent>
          </Dialog>
        )
    }
}
