import { Button, Card, Chip, Container, Divider, Grid } from '@mui/material'
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';

import React from 'react'
import FileUpload from '../components/file-upload/FileUpload';

import * as decisionService from '../services/decisionService';
import * as proposalService from '../services/proposalService';
import * as fileService from '../services/fileService';
import * as conversationService from '../services/conversationService';
import Input from '../components/input/Input';
import { DECISION_STATUS, getProjectId, getRole, mapFileProps, USER_ROLES } from '../utils/utils';
import FilePreview from '../components/file-preview/FilePreview';
import AddIcon from '@mui/icons-material/Add';

import '../styles/decision-details.scss';
import Icon from '../components/icon/Icon';
import SignProposalDialog from '../components/sign-proposal-dialog/SignProposalDialog';
import ConfirmDialog from '../components/confirm-dialog/ConfirmDialog';
import DataField from '../components/data-field/DataField';

export default class DecisionDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProposal: null,
            signDialog: false,
            decision: {
              budget: 0,
              status: null,
              deadline: '',
              description: '',
              decisionTypeId: {
                  title: ''
              },
              unitId: {
                  name: '',
                  ownerId: {
                    firstName : '',
                    lastName: '',
                  }
              }
            },
            proposals: [],
            configuringProposal: false,
            proposalPrice: 0,
            proposalTitle: '',
            extraFiles: [],
          }

          this.uploader = React.createRef();
          this.extraUploader = React.createRef();
    }


    async componentDidMount() {
        const { decisionId } = this.props.match.params;
        const decisionInfo = await decisionService.getDecision(decisionId);
        const proposals = await proposalService.getProposals(decisionId);
        this.setState({ decision: decisionInfo.decision, proposals })
    }

    createProposal = async () => {
        try {
            const { decisionId } = this.props.match.params;
            const { proposalTitle, proposalPrice } = this.state;
            const proposal = { title: proposalTitle, price: proposalPrice, files: mapFileProps(this.state.files)}

            const data = await proposalService.createProposal(getProjectId(), decisionId, proposal);
            await this.uploader.current.upload(data.uploadUrls);
    
            toast.success('Voorstel aangemaakt');
            this.setState({ proposalTitle: '', proposalPrice: 0, configuringProposal: false })
            window.location.reload();
        } catch (error) {
            toast.error('Er ging iets mis');
            console.log(error);
        }
    }

    addExtraFiles = async (proposal) => {
        try {
            
            const data = await proposalService.addExtraFiles(proposal._id, { files: mapFileProps(this.state.extraFiles) });
            await this.extraUploader.current.upload(data.uploadUrls);

            toast.success('Bestanden toegevoegd');
            window.location.reload();
        } catch (error) {
            toast.error('Er ging iets mis');
        }
    }

    deleteFiles = async (file) => {
            await fileService.deleteFile(file._id);
            window.location.reload();
    }

    markForDelete = (e, file) => {
        e.stopPropagation();
        this.setState({deleteFileId: file._id});
    }

    approveProposal = async (proposal) =>  this.setState({ selectedProposal: proposal, signDialog: true })

    takeProposalAction = async (proposal, status) => {
        try {
            await proposalService.takeProposalAction(proposal._id, status);
            // toast.success('Wijzigingen aangevraagd');
            window.location.reload();
        } catch (error) {
            console.log(error);
            toast.error(`Er ging iets mis:  '${error.response.data?.error}'`)
        }
    }

    deleteProposal = async (proposal) => {
        try {
            await proposalService.deleteProposal(proposal._id);
            window.location.reload();
        } catch (error) {
            toast.error('Er ging iets mis...');
        }
    }

    startChat = async () => {
        const { decision } = this.state;
        
        const conversation = await conversationService.createConversation(decision._id, decision.unitId._id);
        this.props.history.push(`/conversations/${conversation._id}`)
    }

    render() {
        const { configuringProposal, proposalTitle, proposalPrice, proposals, selectedProposal, signDialog, deletePropsalId } = this.state;
        const { budget, deadline, decisionTypeId, unitId, status, description } = this.state.decision;
        const firstName = (unitId && unitId.ownerId) && unitId.ownerId.firstName || '';
        const lastName = (unitId && unitId.ownerId) && unitId.ownerId.lastName || '';
        const unitOwnerName = `${firstName} ${lastName}`;

        const { title } = decisionTypeId;
        const { name: unitName } = unitId;

        const allowedProposals = getRole() == USER_ROLES.SUPER_ADMIN ?
            proposals : proposals.find(p => p.status == DECISION_STATUS.APPROVED) ?
                proposals.filter(p => p.status == DECISION_STATUS.APPROVED) : proposals ;


        let decisionLabel;
        let statusBackground;
        let statusColor = 'black';
        if (status == DECISION_STATUS.UNDECIDED) {
            decisionLabel = 'Openstaand';
        }
        if (status == DECISION_STATUS.CHANGES_REQUESTED) {
            decisionLabel = 'Wijzigingen gevraagd';
            statusBackground= 'grey';
            statusColor= 'white';
        }
        if (status == DECISION_STATUS.REFUSED) {
            decisionLabel = 'Geweigerd';
            statusBackground = '#ed8b8b';
            statusColor= 'white';
        }
        if (status == DECISION_STATUS.APPROVED) {
            decisionLabel= 'Goedgekeurd'
            statusBackground = 'var(--color-success)';
            statusColor= 'white'
        }  
        
        const approvedProposal = proposals && proposals.length ? proposals.find(p => p.status == DECISION_STATUS.APPROVED) : null;
        console.log('all', proposals)
        console.log('app', approvedProposal)

        return(
            <Container>
                <h2 className='dd-title'>{title} <Chip style={{ backgroundColor: statusBackground, color: statusColor }} label={decisionLabel}/></h2>
                <p>{description}</p>
                <Grid container style={{ marginTop: '12px'}} rowGap={1}>
                    <Grid item xs='12' className='decision-details-property-container'>
                        <Icon i='house' />
                        <span>{unitName} - {unitOwnerName.trim().length > 0 ? unitOwnerName : 'Nog geen eigenaar'}</span>
                    </Grid>
                    <Grid item xs='12' className='decision-details-property-container'>
                        <Icon i='calculate' />
                        <span>Budget: € {budget}</span>

                    </Grid>
                    <Grid item xs='12' className='decision-details-property-container'>
                        <Icon i='calendar_month' />
                        <span>Deadline: {dayjs(deadline).format('DD-MM-YYYY') || 'Geen deadline'}</span>
                    </Grid>
                    {(status == DECISION_STATUS.APPROVED && approvedProposal && approvedProposal.price > budget ) && <Grid item xs='12' className='decision-details-property-container' >
                        <Icon i='euro' />
                        <span>Meerwerken: € {approvedProposal.price - budget}</span>
                    </Grid>}
                </Grid>
                <div className='decision-details-proposals-container'>
                    {!configuringProposal && (getRole() != USER_ROLES.CLIENT) &&
                         <Button startIcon={<AddIcon />} onClick={() => this.setState({ configuringProposal: true })}>Nieuw voorstel</Button>}
                    
                    {!configuringProposal && <div className='dd-proposals-container'>
                        {allowedProposals.map(p => <Card className='dd-proposal-wrapper'>
                            {p.status != DECISION_STATUS.UNDECIDED && <div className={`dd-status-bar ${p.status.toLowerCase()}_color`}>
                                {p.status == DECISION_STATUS.APPROVED && <><Icon i='check' />aanvaard</>}
                                {p.status == DECISION_STATUS.REFUSED && <><Icon i='close' />Geweigerd</>}
                                {p.status == DECISION_STATUS.CHANGES_REQUESTED && 'Wijzigingen gevraagd'}
                            </div>}
                            <div className='dd-proposal-content-wrapper'>
                                <Grid container>
                                    <Grid item md={12} xs={12} >
                                        <div className='dd-proposal-header'>
                                            <p className='dd-proposal-title'>{p.title}</p>
                                        </div>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                     {getRole() == USER_ROLES.CLIENT && <div>
                                        {p.status == DECISION_STATUS.UNDECIDED && <Grid container className='dd-actions-container'>
                                            <Button className='dd-action-btn' startIcon={<Icon i='done'/>} onClick={() => this.approveProposal(p)}>Goedkeuren</Button>
                                            <Button className='dd-action-btn'  startIcon={<Icon i='build' />} onClick={() => this.setState({changesProposalId: p._id})}>Wijzigingen vragen</Button>
                                            <Button className='dd-action-btn' startIcon={<Icon i='close' />} onClick={() => this.setState({refuseProposalId: p._id})}>Voorstel weigeren</Button>
                                            
                                        </Grid>
                                        }
                                        <ConfirmDialog open={this.state.changesProposalId == p._id} title='Wijzigingen vragen?'
                                                    confirmText='Ja'
                                                    description='Neem vervolgens contact op via de knop "contact opnemen". Deze knop verschijnt later onder de documenten van dit voorstel.'
                                                    onClick={() => this.takeProposalAction(p, DECISION_STATUS.CHANGES_REQUESTED)} 
                                                    onClose={() => this.setState({ changesProposalId: null })} 
                                                />
                                        <ConfirmDialog open={this.state.refuseProposalId == p._id} title='Voorstel weigeren?'
                                                    confirmText='Ja'
                                                    description='Wilt u het Voorstel weigeren?'
                                                    onClick={() => this.takeProposalAction(p, DECISION_STATUS.REFUSED)} 
                                                    onClose={() => this.setState({ refuseProposalId: null })} 
                                                />
                                    </div>}        
                                    {getRole() == USER_ROLES.SUPER_ADMIN &&  <div className='dd-actions-container'>
                                        <Icon i='delete' color='red' onClick={(e) => this.setState({ deletePropsalId: p._id })}/>
                                        <ConfirmDialog open={deletePropsalId == p._id} title='Voorstel verwijderen?'
                                            type='delete' confirmText='Verwijderen'
                                            description='<b>Let op!</b> Deze actie is onomkeerbaar'
                                            onClick={() => this.deleteProposal(p)} 
                                            onClose={() => this.setState({ deletePropsalId: null })} 
                                        />
                                    </div>}
                          
                                    </Grid>
                                    <Grid container spacing={2}>
                                            <Grid item md={3} xs={12}>
                                                <DataField label='Brutto offerte (excl. BTW)' value={p.price} />
                                            </Grid>
                                            {(status != DECISION_STATUS.APPROVED && !approvedProposal && p.price > budget ) && <Grid item md={3} xs={12}>
                                                <DataField label='Meerwerken (excl. BTW)' value={p.price - budget} />
                                            </Grid>}
                                        </Grid>
                                </Grid>
                            <h3>Documenten</h3>
                            <div className='dd-proposal-content'>
                                <Grid container columnSpacing={2} rowSpacing={0}>
                                {p.files.map(f =>
                                    <> 
                                        <Grid item xs='12' md='3'><FilePreview file={f} showSigned card isDelete onDelete={(e) => this.markForDelete(e, f)}/> </Grid>
                                        <ConfirmDialog open={this.state.deleteFileId == f._id} title='bestand verwijderen?'
                                            confirmText='Verwijderen'
                                            type='delete'
                                            description='Wilt u dit bestand verwijderen?'
                                            onClick={() => this.deleteFiles(f)} 
                                            onClose={() => this.setState({ deleteFileId: null })} 
                                        />
                                    </>
                                )}
                                </Grid>
                            </div>
                            
                            {(getRole() == USER_ROLES.SUPER_ADMIN || getRole() == USER_ROLES.SUPPLIER) && 
                            <div>
                                <div className='dd-add-documents' onClick={(e) => this.setState({ editingProposalId: p._id })}><Icon i='add' /><u>documenten toevoegen</u></div> 
                            </div>}
                            <ConfirmDialog
                                open={this.state.editingProposalId == p._id} onClose={() => this.setState({ editingProposalId: null })}
                                description={`Voeg nieuwe documenten toe aan uw voorstel.  <b>Let wel op:</b> het voorstel zal opnieuw ondertekend moeten worden door de klant.`}
                                title='Documenten toevoegen' confirmText='Documenten toevoegen'
                                onClick={() => this.addExtraFiles(p)}
                            >
                                <FileUpload ref={this.extraUploader} onChange={(files) => this.setState({ extraFiles: files })} />
                            </ConfirmDialog>
                            
                            {p.status == DECISION_STATUS.CHANGES_REQUESTED && <div>
                                <Button startIcon={<Icon i='chat' />} sx={{ marginTop: '12px'}} onClick={() => this.startChat()}>Contact opnemen</Button>
                            </div>}

                            </div>
                        </Card>)}
                    </div>}

                    {!configuringProposal && allowedProposals.length == 0 && <Card className='dd-details-empty-proposals'>
                        <p className='dd-details-empty-description'>Nog geen voorstellen beschikbaar</p>
                    </Card>}

                    {configuringProposal && <div>
                        <h2>Nieuw voorstel</h2>
                        <Card>
                            <Input placeholder={`${title} ...`} label='Titel' value={proposalTitle} onChange={(e) => this.setState({ proposalTitle: e.target.value })}/>
                            <Input label='Prijs' value={proposalPrice} type='number' onChange={(e) => this.setState({ proposalPrice: e.target.value })}/>
                            <h2>Documenten</h2>
                            <FileUpload ref={this.uploader} onChange={(files) => this.setState({ files })} />
                            <Button onClick={() => this.createProposal()}>Voorstel aanmaken</Button>
                        </Card>
                    </div>}
                   
                </div>
                {signDialog && <SignProposalDialog {...selectedProposal} name={unitOwnerName} onClose={() => this.setState({ signDialog: false })}/>}
            </Container>
        )
    }
}
