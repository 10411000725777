export const USER_ROLES = {
  CLIENT: 'client',
  TECHNICAL: 'technical',
  SUPPLIER: 'supplier',
  SUPER_ADMIN: 'super_admin'
}

export const DECISION_STATUS = {
  APPROVED: 'APPROVED',
  UNDECIDED: 'UNDECIDED',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  REFUSED: 'REFUSED'
}

export const  FILE_TAGS = {
  INVOICE: 'offerte',
  NOTINVOICE: 'geen offerte'
  }

export const generateMongoDbId = () => {
  var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
      return (Math.random() * 16 | 0).toString(16);
  }).toLowerCase();
};

export const formatDuration = (duration) => {
  return ``
}

export const getDateTimeString = (date) => {
  return date.toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
}).replace(" ", "T")
}


export const getDayMonth = (date) => {
  const days = date.getDate();
  const month = date.getMonth();

  const formattedDays = days.toString().length == 1 ? `0${days}` : days;
  const formattedMonth = month.toString().length == 1 ? `0${month}` : month;

  return `${formattedDays}/${formattedMonth}`
}

export const getProjectId = () => {
  return localStorage.getItem('projectId');
}

export const getRole = () => localStorage.getItem('role');

export const mapFileProps = (files) => {
  // Transform File objects to javascript objects with the most important props
  return files.map(f => ({ name: f.name, type: f.type, size: f.size, index: f.index, tag: f.tag }));
}

export const getFileUrl = (file) => {
  const { REACT_APP_S3_BUCKET_URL } = process.env;

  if(file.isGeneral){
    return `${REACT_APP_S3_BUCKET_URL}/${file.sourceId}`
  }else{
    return `${REACT_APP_S3_BUCKET_URL}/${file._id}`
  }
  
}

export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;
