import React from 'react'
import { Card, Chip, Container, Divider, Grid } from '@mui/material';

import { DECISION_STATUS, getProjectId, getRole, USER_ROLES } from '../utils/utils';
import * as decisionService from '../services/decisionService';
import * as newsService from '../services/newsArticleService';
import * as userService from '../services/authService.js'
import NewsPreview from '../components/news-preview/NewsPreview';
import DecisionPreview from '../components/decision-preview/DecisionPreview';


export default class Dashboard extends React.Component {
    state = {
       news: [],
       decisions: [],
       units: [],
       firstName: ''
    }

    async componentDidMount() {
        if (getRole() == USER_ROLES.CLIENT) {
            const response = await decisionService.getDecisions(getProjectId());
            this.setState({ units: response.units, decisions: response.decisions });
        }

        const news = await newsService.getDashboardNewsArticles(getProjectId());
        this.setState({ news });

        const me = await userService.getMe();
        this.setState({firstName: me.firstName})
    }
    

    render() {
        const { units, decisions, news, firstName } = this.state;
        const openDecisions = decisions.filter(d => d.status != DECISION_STATUS.APPROVED && d.status != DECISION_STATUS.REFUSED);
        return(
            <Container>
              {getRole() == USER_ROLES.CLIENT && <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={12} md={12}>
                    <h2>Hallo {firstName}!</h2>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div>
                        {news.map(n => <NewsPreview {...n}/>)}
                    </div>
                </Grid>
                <Grid item  xs={12} md={5}>
                    <Card>
                        <h3>Uw units</h3>
                        {units.map(u => <p>{u.name}</p>)}
                    </Card>
                    <Card>
                        <h3 style={{ margin: '8px 0px'}}>{openDecisions.length} {openDecisions.length == 1 ? 'beslissing': 'beslissingen'} te nemen</h3>
                        <Divider sx={{ marginBottom: '8px'}} />
                        {openDecisions.map(d => <DecisionPreview {...d} history={this.props.history}/>)}
                    </Card>
                </Grid>
            </Grid>}

            {getRole() == USER_ROLES.SUPER_ADMIN && <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item md={6}>
                    <div>
                        {news.map(n => <NewsPreview {...n}/>)}
                    </div>
                </Grid>
            </Grid>}
            </Container>
        )
    }
}
