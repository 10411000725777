import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

axios.interceptors.request.use(
  function (config) {

    const impersonatingToken = localStorage.getItem('impersonating-token');
    if (impersonatingToken) {
      config.headers["Authorization"] = impersonatingToken;
    } else {
      config.headers["Authorization"] = localStorage.getItem("jwt-token");
    }
    

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (error) => {
    if (error.response && error.response.status == 401) {
      window.location.href = "/login";
      localStorage.removeItem("role");
    }
    return Promise.reject(error);
  }
);
export const httpClient = axios;
export const s3Client = axios.create({

})

