import { httpClient } from "./index.js";

export const createProposal = async (projectId, decisionId, proposal) => {
    const response = await httpClient.post(`proposal/project/${projectId}/decision/${decisionId}`, proposal);
    return response.data;
}


export const getProposals = async (decisionId) => {
    const response = await httpClient.get(`proposal/decision/${decisionId}`);
    return response.data;
}

export const takeProposalAction = async (proposalId, status) => {
    const response = await httpClient.post(`proposal/${proposalId}/status/${status}`);
    return response.data;
}


export const deleteProposal = async (proposalId) => {
    const response = await httpClient.delete(`proposal/${proposalId}`);
    return response.data;
}


export const addExtraFiles = async (proposalId, data) => {
    const response = await httpClient.put(`proposal/${proposalId}/documents`, data);
    return response.data;
}

export const getProposalActionsOfProject = async (projectId) => {
    const response = await httpClient.get(`proposal/actions/${projectId}`);
    return response.data;
}