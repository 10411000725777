import { Button } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react'
import { toast } from 'react-hot-toast';
import FileUpload from '../../components/file-upload/FileUpload';
import Input from '../../components/input/Input';

import * as projectService from '../../services/projectService';
import { mapFileProps } from '../../utils/utils';

export default class ProjectConfig extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            _id: '',
            name:'',
            location: '',
            files: [],
        }
        this.uploader = React.createRef();
    }

    async componentDidMount() {
        const id = new URLSearchParams(this.props.location.search).get("id");
        const mode =  new URLSearchParams(this.props.location.search).get("mode");
        if (mode == 'edit') {
            const project = await projectService.getProject(id)
            this.setState({ _id: project._id, name: project.name, location: project.location, editing: true })
        }
    }

    saveProject = async () => {
        const { _id ,name, location, editing, files } = this.state;
        try {
            const { uploadUrls } = editing ?
                await projectService.updateProject(_id, { name, location, files: mapFileProps(files)}) :
                await projectService.createProject({ name, location, files: mapFileProps(files) }) 

            await this.uploader.current.upload(uploadUrls);
            toast.success('Succesfully saved project');
            this.props.history.push('/manage/projects')
        } catch (error) {
            toast.error(`Failed to save Project`);
        }
    }    

    render() {
       const {  name, location } = this.state;

        return(
            <Container>
                <h1>Project configureren</h1>
                <Input name='Name' label='Name' required value={name}  onChange={(e) => this.setState({ name: e.target.value })}/>
                <Input name='Location' label='Locatie' required value={location}  onChange={(e) => this.setState({ location: e.target.value })}/>
                <FileUpload fileTypes='image/*,video/*' disableTags single ref={this.uploader} onChange={(files) => this.setState({ files })}/>
                <Button sx={{ marginTop: '16px'}} onClick={() => { this.saveProject() }}>Project opslaan</Button>
            </Container>
        )
    }
}
