import { httpClient, s3Client } from "./index.js";

export const getDecisionGroup = async (projectId) => {
    const response = await httpClient.get(`decision/project/${projectId}/type`);
    return response.data;
}

export const createDecisions = async (projectId, decision) => {
    const response = await httpClient.post(`decision/project/${projectId}/type`, decision);
    return response.data;
}

export const getDecisionsOfType = async (decisionTypeId) => {
    const response = await httpClient.get(`decision/decisions/${decisionTypeId}`);
    return response.data;
}

export const getDecision = async (decisionId) => {
    const response = await httpClient.get(`decision/${decisionId}`);
    return response.data;
}

export const getDecisions = async (projectId) => {
    const response = await httpClient.get(`decision/project/${projectId}/decision`);
    return response.data;
}

export const getDecisionTopicsOfUnit = async (unitId) => {
    const response = await httpClient.get(`decision/unit/${unitId}`);
    return response.data;
}

export const createDecision = async (decisionTypeId, decision) => {
    const response = await httpClient.post(`decision/decisions/${decisionTypeId}/decision`, decision);
    return response.data;
}

export const updateDecision = async (decisionTypeId, decisionId , decision) => {
    const response = await httpClient.put(`decision/decisions/${decisionTypeId}/decision/${decisionId}`, decision);
    return response.data;
}

export const getDecisionTypesForDocuments = async (projectId) => {
    const response = await httpClient.get(`decision/project/${projectId}/type/documents`);
    return response.data;
}

export const getDecisionType = async (decisionTypeId) => {
    const response = await httpClient.get(`decision/decision-type/${decisionTypeId}`);
    return response.data;
}

export const deleteDecision = async (decisionId) => {
    const response = await httpClient.delete(`decision/${decisionId}`);
    return response.data;
}

export const deleteDecisionType = async (decisionTypeId) => {
    const response = await httpClient.delete(`decision/decision-type/${decisionTypeId}`);
    return response.data;
}