import React from 'react'
import { Button, Container } from '@mui/material';
import { toast } from 'react-hot-toast';
import HtmlEditor from '../../components/html-editor/HtmlEditor';
import { generateMongoDbId, getProjectId, mapFileProps } from '../../utils/utils'
import * as articleService from '../../services/newsArticleService';
import FileUpload from '../../components/file-upload/FileUpload';
import Icon from '../../components/icon/Icon';

export default class NewsConfig extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            articleId: generateMongoDbId(),
            content: '',
            files: [],
            editing: false
        }
        this.uploader = React.createRef();
    }


    async componentDidMount() {
        const id = new URLSearchParams(this.props.location.search).get("id");
        const mode =  new URLSearchParams(this.props.location.search).get("mode");
        if (mode == 'edit') {
            const toUpdate = await articleService.getNewsArticleById(id)
            this.setState({ articleId: toUpdate._id, content: toUpdate.content, editing: true })
        }
        
    }

    saveNewsArticle = async () => {
        const { articleId, content, files, editing } = this.state;
        try {
            const { uploadUrls } = editing ?
                await articleService.updateNewsArticle({_id: articleId, content, files: mapFileProps(files), projectId: getProjectId() }) :
                await articleService.createNewsArticle({_id: articleId, content, files: mapFileProps(files), projectId: getProjectId() });
            await this.uploader.current.upload(uploadUrls);

            toast.success('Nieuwsartikel aangemaakt');
            this.props.history.push('/news')
        } catch (error) {
            console.log(error);
            toast.error(`Kon nieuwsartikel niet aanmaken`);
        }
    }    

    render() {
       const { articleId } = this.state;

        return(
            <Container>
                <h1>Nieuws configureren</h1>
                <div>
                    <p>Foto of video</p>
                    <FileUpload fileTypes='image/*,video/*' disableTags single ref={this.uploader} onChange={(files) => this.setState({ files })}/>
                </div>
                <HtmlEditor initialValue={this.state.content} onChange={(html) => this.setState({ content: html })} objectId={articleId}/>
                <Button startIcon={<Icon i='save' />} onClick={() => { this.saveNewsArticle() }}>Artikel opslaan</Button>
            </Container>
        )
    }
}
