import React from "react";
import { Switch } from "react-router-dom";

import Sidebar from './components/sidebar/Sidebar.jsx';
import CustomRoute from "./components/custom-route/CustomRoute";
import { styled } from "@mui/system";
import { AppBar, Button, useMediaQuery } from "@mui/material";
import News from "./views/News.jsx";
import NewsConfig from "./views/admin/NewsConfig.jsx";

import Decisions from "./views/Decisions.jsx";
import DecisionDetails from "./views/DecisionDetails.jsx";
import Dashboard from "./views/Dashboard.jsx";
import Conversations from "./views/Conversations.jsx";
import Documents from "./views/Documents.jsx";
import DecisionTypeConfig from "./views/admin/DecisionTypeConfig";
import Conversation from "./views/Conversation.jsx";
import NewConversation from "./views/NewConversation.jsx";
import AdminDecisions, { WithScreenAdminDecisions } from "./views/admin/AdminDecisions.jsx";
import ConfigOverviewRoutes from "./views/config/ConfigOverviewRoutes.jsx";
import DecisionTypeDocuments from "./views/DecisionTypeDocuments.jsx";
import DecisionConfig from "./views/admin/DecisionConfig.jsx";
import ImpersonatingContainer from "./components/impersonating-container/ImpersonatingContainer.jsx";
import { USER_ROLES } from "./utils/utils.js";
import Icon from "./components/icon/Icon.jsx";
import ProposalActionOverview from "./views/admin/proposalActionOverview.jsx";

import * as projectService from './services/projectService';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 96,
  height: 'calc(100% - 96px)',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '280px'
  },

}));


const SimpleNavbar = ({ open, history, location }) => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });

  const impersonatingToken = localStorage.getItem('impersonating-token');

  const stopImpersonating = (e) => {
    localStorage.removeItem('impersonating-token');
    localStorage.setItem('role', USER_ROLES.SUPER_ADMIN);
    window.location.reload();
  }

  return (
    <AppBar
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', 
        backgroundColor: impersonatingToken ? 'yellow':  lgUp ? 'transparent' : 'white',
        minHeight: '60px',
        padding: '0px 18px',
        color: 'black', 
        left: lgUp ? '280px' : '0px',
        width: lgUp ? 'calc(100% - 280px)' : '100%',
        boxShadow: 'none'
      }}
    >
      {!lgUp && <div onClick={() => open()} style={{ cursor: 'pointer' }}>
        <Icon i='menu'/>
      </div>}
      {impersonatingToken && <div style={{ display: 'flex'}}>
        <p>Impersonatie: {localStorage.getItem('impersonating-user')} | </p>
        <p style={{ cursor: 'pointer', marginLeft: '4px' }} onClick={() => stopImpersonating()}><u>stop impersonatie</u></p>
      </div>}
      <div onClick={() => { localStorage.removeItem('jwt-token'); history.push('/login') }} style={{ display: 'flex', marginLeft: 'auto', cursor: 'pointer'}}>
        <Icon i='logout' /> Afmelden
      </div>
    </ AppBar>
  )
}

export default class AdminOverviewRoutes extends React.Component {
  state = {
    isOpen: false,
    projects :[]
  }

  async componentDidMount() {
    const projects = await projectService.getProjects();
    this.setState({projects})
  }

  render() {
    const { history, location } = this.props;
    const { isOpen, projects } = this.state;
    return (
      <div className="admin-container" style={{ height: '100%'}}>
        <Sidebar projects={projects} history={history} location={location} isOpen={isOpen} close={() => this.setState({ isOpen: false })} />

        <Switch>
          <DashboardLayoutRoot>
            <SimpleNavbar history={history} location={location} open={() => this.setState({ isOpen: !this.state.isOpen })}/>

            <CustomRoute exact path='/dashboard' component={Dashboard} />
            <CustomRoute exact path='/conversations' component={Conversations} />
            <CustomRoute exact path='/conversations/:conversationId' component={Conversation} />
            <CustomRoute exact path='/new-conversation/' component={NewConversation} />

            <CustomRoute exact path='/documents' component={Documents} />
            <CustomRoute exact path='/documents/:decisionTypeId' component={DecisionTypeDocuments} />
            <CustomRoute exact path='/decisions' component={Decisions} />
            <CustomRoute exact path='/decisions/:decisionId' component={DecisionDetails} />

            <CustomRoute exact path='/decisions/type/:decisionTypeId' component={WithScreenAdminDecisions} />
            <CustomRoute exact path='/decision-type-config' component={DecisionTypeConfig} />
            <CustomRoute exact path='/decisions/type/:decisionTypeId/decision-config' component={DecisionConfig} />

            <CustomRoute exact path='/actions/:projectId' component={ProposalActionOverview} />

            <CustomRoute path='/manage' component={ConfigOverviewRoutes} />

            <CustomRoute exact path='/news' component={News} />
            <CustomRoute exact path='/news/config' component={NewsConfig} />

          </DashboardLayoutRoot>
          
          
        </Switch>
      </div>
    );
  }
}
