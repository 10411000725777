import { BrowserRouter, Route, Switch } from "react-router-dom";
import StyledToaster from "./components/styled-toaster/styled-toaster.jsx";


import Login from "./views/Login.jsx";
import AdminOverviewRoutes from "./AdminOverviewRoutes.jsx";
import PasswordConfig from "./views/PasswordConfig.jsx";
import ResetPasswordInit from "./views/PasswordResetInit.jsx";

import * as dayjs from 'dayjs';
import 'dayjs/locale/nl-be';
import ImpersonatingContainer from "./components/impersonating-container/ImpersonatingContainer.jsx";


function App() {
  dayjs.locale('nl-be')

  return (
      <BrowserRouter>
      <StyledToaster />
      <ImpersonatingContainer />

        <Switch>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/password" component={PasswordConfig}/>
          <Route exact path="/reset-password" component={ResetPasswordInit}/>

          {/* SPECIAL ADMIN ROUTES WITH SIDEBAR */}
          <Route  path='/' component={AdminOverviewRoutes} />
        </Switch>
       
      </BrowserRouter>
  );
}

export default App;
